/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import Stepper from "@/components/Stepper";
import ContentLayout from "@/components/layouts/ContentLayout";
import GeneralInfoForm, { GeneralInformationSchema } from "./GeneralInfoForm";
import SelectServiceForm, { SelectServiceSchema } from "./SelectServiceForm";
import AdditionalInfoForm, { AdditionalInformationSchema } from "./AdditionalInfoForm";
import { useState } from "react";
import { useRouter } from "next/navigation";
import APIClient from "./apiClient";
import { IDict } from "@/components/constants";
import { toast } from "sonner";
import { STEPS } from "./constants";

// Change all record mappings to "WS Service Orders" uncomment old if needed
const RECORD_MAPPING: { [key: string]: string } = {
  Service: "0128c000001zT4PAAU",
  Invoice: "0128c000001zT4PAAU",
  Relocation: "0128c000001zT4PAAU",
  "Lease Extension": "0128c000001zT4PAAU",
  Return: "0128c000001zT4PAAU",
  "AP Information": "0128c000001zT4PAAU",
};

interface RequestServicePageState {
  currentStep: number;
  formData: Partial<GeneralInformationSchema | SelectServiceSchema | AdditionalInformationSchema>;
}

export default function RequestSupportMainComponent({ formCopy }: { formCopy: IDict }) {
  const api = new APIClient();
  const router = useRouter();
  const [state, setState] = useState<RequestServicePageState>({
    currentStep: 0,
    formData: {},
  });

  const [submitting, setSubmitting] = useState(false);

  const handleGeneralInfoDataAndOpenSelectServiceForm = (data: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setState({ ...state, formData: { ...state.formData, ...data }, currentStep: 2 });
  };

  const handleSelectServiceDataAndOpenAdditionalInfoForm = (data: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setState({ ...state, formData: { ...state.formData, ...data }, currentStep: 1 });
  };

  const handleAdditionalInfoDataAndSendDataToServer = (data: any) => {
    setSubmitting(true);

    const newData = { ...state, formData: { ...state.formData, ...data } };

    setState(newData);

    const requestData: any = { ...newData.formData, ...data };
    // delete keys that are not created in salesforce yet
    // todo: remove this when salesforce is ready
    const missingKeys: (keyof AdditionalInformationSchema)[] = [
      // "prefDate",
      // "prefTime",
      // "commuicationWay",
      "New_Site_Country__c",
    ];

    missingKeys.forEach((key) => {
      delete requestData[key];
    });

    const RecordTypeId = RECORD_MAPPING[(requestData as any)["Request_Type__c"]];

    // *** 🧙🏼‍♂️ Magic Remapping START 🪄 ***
    // Due to business decisions we need to remap the payload to match the Salesforce fields
    const doTheRemapping = (payload: any) => {
      const newPayload = {
        ...payload,
      };

      // ! we need to use country short name, but it comes from UI form as long name
      // ! when fetching all info about place, we save long name and short name in storage
      // ! and here we check (line 119) if the long name matches to value we get from hook form
      const savedState = localStorage.getItem("siteState") || "";
      const stateData = JSON.parse(savedState);

      // Rule 1: Concatenate Portal_First_Name__c & Portal_Last_Name__c to SuppliedName
      const firstNameAndLastName = [];
      if (typeof payload.Portal_First_Name__c === "string") {
        firstNameAndLastName.push(payload.Portal_First_Name__c);
      }
      if (typeof payload.Portal_Last_Name__c === "string") {
        firstNameAndLastName.push(payload.Portal_Last_Name__c);
      }
      const SuppliedName = firstNameAndLastName.join(" "); // Trick to remove spaces if one of the names is missing
      delete newPayload.Portal_First_Name__c;
      delete newPayload.Portal_Last_Name__c;
      delete newPayload.nonexistent;
      newPayload.SuppliedName = SuppliedName;

      // Rule 2: Remove Portal_Email_Address__c and Portal_Phone_Number__c
      const SuppliedEmail = payload.Portal_Email_Address__c;
      const SuppliedPhone = payload.Portal_Phone_Number__c;
      // delete newPayload.Portal_Email_Address__c;
      delete newPayload.Portal_Phone_Number__c;
      newPayload.SuppliedEmail = SuppliedEmail;
      newPayload.SuppliedPhone = SuppliedPhone;

      // Rule 3: Remove Requested_Country__c, Requested_State__c, Requested_Zip__c, Requested_City__c, Req
      const SiteCountryWeb = payload.Requested_Country__c;
      const SiteStateWeb = payload.Requested_State__c === stateData?.long ? stateData?.short : "";
      const SiteZipWeb = payload.Requested_Zip__c;
      const SiteCityWeb = payload.Requested_City__c;
      const SiteStreetWeb = payload.Requested_Address1__c;
      delete newPayload.Requested_Country__c;
      delete newPayload.Requested_State__c;
      delete newPayload.Requested_Zip__c;
      delete newPayload.Requested_City__c;
      delete newPayload.Requested_Address1__c;
      newPayload.Site_Country_Web__c = SiteCountryWeb;
      newPayload.Site_State_Web__c = SiteStateWeb;
      newPayload.Site_Zip_Web__c = SiteZipWeb;
      newPayload.Site_City_Web__c = SiteCityWeb;
      newPayload.Site_Street_Web__c = SiteStreetWeb;

      // Rule 4: Remap units
      const Unit__c = payload?.units?.[0];
      delete newPayload.units;
      newPayload.Unit__c = Unit__c;

      // Rule 5: Remove prefDate, add Requested_Pick_Up_Date__c
      if (payload.prefDate) {
        const Requested_Pick_Up_Date__c = payload.prefDate;
        delete newPayload.prefDate;
        // Format date to ISO string but only use the YYYY-MM-DD part
        newPayload.Requested_Pick_Up_Date__c = new Date(Requested_Pick_Up_Date__c).toISOString().split("T")[0];
      }

      // Rule 6:
      newPayload.OwnerId = "00G8c000006wyqjEAA"; // Hardcoded for now
      newPayload.Type = "Feature Request"; // Hardcoded for now

      if (payload.Request_Type__c === "Service") {
        newPayload.Reason = "Complex functionality"; // Hardcoded for now
      }

      // Rule 6: USE Case_Issue__c and set it to "Request" !!! SUPER IMPORTANT !!! Or else Request_Type__c will be overwritten
      newPayload.Case_Issue__c = "Request";

      // Rule 7: Remove Request_Type__c WEB-1335
      newPayload.Subject = `${payload.Request_Type__c} - WS Website`;
      delete newPayload.Request_Type__c;

      newPayload.Origin = "Web";

      // Parse data from cookie to get utm values from cookie
      const utm_source = document.cookie
        .split("; ")
        .find((row) => row.startsWith("utm_source"))
        ?.split("=")[1];

      const utm_medium = document.cookie
        .split("; ")
        .find((row) => row.startsWith("utm_medium"))
        ?.split("=")[1];

      const utm_campaign = document.cookie
        .split("; ")
        .find((row) => row.startsWith("utm_campaign"))
        ?.split("=")[1];

      const utm_term = document.cookie
        .split("; ")
        .find((row) => row.startsWith("utm_term"))
        ?.split("=")[1];

      const utm_content = document.cookie
        .split("; ")
        .find((row) => row.startsWith("utm_content"))
        ?.split("=")[1];

      if (utm_source) {
        newPayload.utm_source__c = utm_source;
        newPayload.ga_source = utm_source;
      }
      if (utm_medium) {
        newPayload.utm_medium__c = utm_medium;
        newPayload.ga_medium = utm_medium;
      }
      if (utm_campaign) {
        newPayload.utm_campaign__c = utm_campaign;
        newPayload.ga_campaign = utm_campaign;
      }
      if (utm_term) {
        newPayload.utm_term__c = utm_term;
        newPayload.ga_term = utm_term;
      }
      if (utm_content) {
        newPayload.utm_content__c = utm_content;
        newPayload.ga_content = utm_content;
      }

      // Done
      return newPayload;
    };
    const remappedRequestData = doTheRemapping(requestData);
    // *** 🧙🏼‍♂️ Magic Remapping END 🪄 ***

    const payload = {
      Operation: "create",
      ObjectType: "case",
      ExternalID: [],
      sObjects: {
        sObject: [{ ...remappedRequestData }],
      },
    };

    if (RecordTypeId && RecordTypeId !== "N/A") {
      payload.sObjects.sObject[0]["RecordTypeId"] = RecordTypeId;
    }

    //
    // POST /services/requestAService {
    //   "Operation": "create",
    //   "ObjectType": "case",
    //   "ExternalID": [],
    //   "sObjects": {
    //     "sObject": [
    //       {
    //         "Request_Type__c": "Service",
    //         "Portal_First_Name__c": "Isus",
    //         "Portal_Last_Name__c": "Bog",
    //         "Portal_Email_Address__c": "Kraken@lolek.com",
    //         "Portal_Phone_Number__c": "+12892800912",
    //         "SuppliedCompany": "Jao",
    //         "Requested_Country__c": "Canada",
    //         "Requested_State__c": "Nova Scotia",
    //         "Requested_Zip__c": "B0A1J0",
    //         "Requested_City__c": "324",
    //         "Requested_Address1__c": "asbduas",
    //         "Contract_Number__c": "56",
    //         "Explanation_of_Problem__c": "Labud",
    //         "Subject": "Service - WS Website",
    //         "Origin": "Web"
    //       }
    //     ]
    //   }
    // }

    api
      .post("/services/requestAService", payload)
      .then(() => {
        setSubmitting(true);
        router.push("/request-service/thank-you");
      })
      .catch((err) => {
        toast.error("Error submitting request");
        console.log(err);
        setSubmitting(false);
      });
  };

  const handlePrevBtnClicked = () => {
    const { currentStep } = state;

    window.scrollTo({ top: 0, behavior: "smooth" });
    setState({ ...state, currentStep: currentStep !== 0 ? currentStep - 1 : 0 });
  };

  // const formsMap = [
  //   {
  //     form: SelectServiceForm,
  //     onSubmitSuccess: handleSelectServiceDataAndOpenAdditionalInfoForm,
  //   },
  //   {
  //     form: GeneralInfoForm,
  //     onPrevHandler: handlePrevBtnClicked,
  //     onSubmitSuccess: handleGeneralInfoDataAndOpenSelectServiceForm,
  //     dict,
  //   },
  //   {
  //     form: AdditionalInfoForm,
  //     onPrevHandler: handlePrevBtnClicked,
  //     onSubmitSuccess: handleAdditionalInfoDataAndSendDataToServer,
  //   },
  // ];

  // const getCurrentForm = () => {
  //   return formsMap[state.currentStep].form;
  // };

  // const getCurrentFormProps: () => IRequestServiceForm = () => {
  //   return {
  //     onPrevHandler: formsMap[state.currentStep].onPrevHandler,
  //     onSubmitSuccess: formsMap[state.currentStep].onSubmitSuccess as OnSubmitSuccessHandler,
  //     formData: state.formData,
  //     submitting,
  //     dict,
  //   };
  // };

  return (
    <div>
      {/* <ImageHeader {...mock} /> */}
      <ContentLayout
        bgColorClass="bg-black-5"
        innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px]"
      >
        <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 py-1 bg-white">
          <Stepper
            className="bg-white"
            steps={STEPS.map((step) => formCopy[step])}
            currentStepIndex={state.currentStep}
          />
        </div>
      </ContentLayout>

      {/* Stepper Form */}
      <ContentLayout
        bgColorClass="bg-black-5"
        innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px] "
      >
        {/* {getCurrentForm()({ ...getCurrentFormProps() })} */}

        {state.currentStep === 0 ? (
          <SelectServiceForm
            submitting={submitting}
            formCopy={formCopy}
            onSubmitSuccess={handleSelectServiceDataAndOpenAdditionalInfoForm}
            formData={state.formData}
          />
        ) : state.currentStep === 1 ? (
          <GeneralInfoForm
            submitting={submitting}
            formData={state.formData}
            onPrevHandler={handlePrevBtnClicked}
            onSubmitSuccess={handleGeneralInfoDataAndOpenSelectServiceForm}
            formCopy={formCopy}
          />
        ) : (
          <AdditionalInfoForm
            formCopy={formCopy}
            submitting={submitting}
            formData={state.formData}
            onPrevHandler={handlePrevBtnClicked}
            onSubmitSuccess={handleAdditionalInfoDataAndSendDataToServer}
          />
        )}
      </ContentLayout>

      {/* Next/Prev Switcher */}
      <ContentLayout
        bgColorClass="bg-black-5"
        innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px] "
      >
        <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 py-10"></div>
      </ContentLayout>
    </div>
  );
}
