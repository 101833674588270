/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import { useSwiper } from "@/hooks/useSwiper";
import { cn } from "@/lib/utils";
import React, { useLayoutEffect, useState } from "react";
import { Swiper } from "swiper/react";
import ContentLayout from "../layouts/ContentLayout";
import ArrowPagination from "../ArrowPagination";
import { customDebounce } from "@/lib/customDebounce";

interface ICarouselFullWidthShiftedLeft {
  children: React.ReactNode;
  bgColorClass: string;
  swiperClassName?: string;
  wrapperClassName: string;
  bg: "dark" | "light";
  breakpoints?: any;
  slidesPerView?: number | "auto";
}

const CarouselFullWidthShiftedLeft = ({
  children,
  bgColorClass,
  bg,
  wrapperClassName,
  swiperClassName,
  breakpoints,
  slidesPerView,
}: ICarouselFullWidthShiftedLeft) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidesOffsetBefore, setSlidesOffsetBefore] = useState(0);
  const { swiperRef, onSwiperChange, currentPage, onNextPageClick, onPrevPageClick, pageCount } = useSwiper();

  const debouncedHandleResize = customDebounce(() => {
    setWindowWidth(window.innerWidth);
  }, 300);

  useLayoutEffect(() => {
    debouncedHandleResize();

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (windowWidth) {
      if (windowWidth < 1024) setSlidesOffsetBefore(16);
      else if (windowWidth < 1280) setSlidesOffsetBefore(32);
      else if (windowWidth < 1536) setSlidesOffsetBefore(80);
      else setSlidesOffsetBefore(Math.round((windowWidth - 1536) / 2) + 104);
    }
  }, [windowWidth]);

  if (!slidesOffsetBefore) return null;

  return (
    <>
      <div className={cn(wrapperClassName, bgColorClass)}>
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onInit={onSwiperChange}
          onSlideChange={onSwiperChange}
          onBreakpoint={onSwiperChange}
          className={cn("flex w-overflow-right", swiperClassName)}
          // className={cn("flex items-stretch  ", swiperClassName)}
          // slidesPerView={1}
          slidesPerView={slidesPerView}
          // spaceBetween={10}
          // slidesOffsetAfter={slidesOffsetBefore}
          slidesOffsetBefore={slidesOffsetBefore}
          breakpoints={breakpoints || {}}
        >
          {children}
        </Swiper>
      </div>
      <ContentLayout bgColorClass={bgColorClass} innerClass="sm:pt-0 pt-0">
        <ArrowPagination
          className={cn("justify-end pt-5", bgColorClass)}
          currentPage={currentPage}
          pageCount={pageCount}
          onPrevPageClick={onPrevPageClick}
          onNextPageClick={onNextPageClick}
          bg={bg}
        />
      </ContentLayout>
    </>
  );
};

export default CarouselFullWidthShiftedLeft;
