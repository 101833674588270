import { useRef, useState } from "react";
import { Swiper as SwiperClass } from "swiper/types";

export function useSwiper(swiperConfig?: { initialPageCount: number }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(swiperConfig?.initialPageCount || 1);
  const swiperRef = useRef<SwiperClass>();

  const onNextPageClick = () => {
    swiperRef.current?.slideNext();
  };

  const onPrevPageClick = () => {
    swiperRef.current?.slidePrev();
  };

  const getToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    swiperRef.current?.slideTo((pageNumber - 1 + pageCount) % pageCount);
  };

  const setPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    swiperRef.current?.slideTo(pageNumber);
  };

  const onSwiperChange = (swiper: SwiperClass) => {
    setTimeout(() => {
      if (swiper.snapGrid) {
        setCurrentPage(swiper.snapIndex + 1);
        setPageCount(swiper.snapGrid.length);
      }
    }, 0);
  };

  return {
    currentPage,
    pageCount,
    swiperRef,
    onSwiperChange,
    onNextPageClick,
    onPrevPageClick,
    setCurrentPage,
    getToPage,
    setPage,
  };
}
