"use client";

import { useEffect, useRef, useState } from "react";
import Image from "next/image";

import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import { colorsMap, fallbackColors } from "../constants";
import { cn } from "@/lib/utils";

type Props = {
  id: string;
  media: string;
  subheading: string;

  paddingBottom: string;
  label: string;
  heading: string;
  isVideo: boolean;
  background: string;
  imageAlt: string;
  placeholder: string;
};

const HeaderMedia = ({
  id,
  media,
  subheading,
  paddingBottom,
  label,
  heading,
  isVideo,
  background,
  imageAlt,
  placeholder,
}: Props) => {
  const { bgColorClass, textColor, labelColor } = colorsMap[background] ?? fallbackColors;
  const mediaWrapperRef = useRef<HTMLDivElement>(null);
  const [mediaHeight, setMediaHeight] = useState("");

  useEffect(() => {
    if (!mediaWrapperRef.current) return;

    setMediaHeight(mediaWrapperRef.current.clientHeight + "px");
  }, []);

  return (
    <ContentLayout
      bgColorClass={bgColorClass}
      id={id}
      className={cn("headerMediaHeight ")}
      innerClass="h-full flex flex-col gap-8 items-center justify-center"
      paddingBottom={paddingBottom}
    >
      <div className="flex flex-col max-w-5xl gap-4 mx-auto">
        {label && (
          <AppText type="LABEL_LARGE" className={cn("text-pretty text-center max-w-3xl mx-auto", labelColor)}>
            {label}
          </AppText>
        )}

        {heading && (
          <AppText type="HEADLINE_LARGE" className={cn("text-pretty text-center max-w-3xl mx-auto", textColor)}>
            {heading}
          </AppText>
        )}

        {subheading && (
          <AppText type="BODY_LARGE" className={cn("text-pretty text-center max-w-3xl mx-auto", textColor)}>
            {subheading}
          </AppText>
        )}
      </div>

      {!isVideo && <ImageBlock imageUrl={media} imageAlt={imageAlt} placeholder={placeholder} />}

      {isVideo && (
        <div ref={mediaWrapperRef} className="flex-1">
          <VideoBlock videoUrl={media} videoPoster={placeholder} mediaHeight={mediaHeight} />
        </div>
      )}
    </ContentLayout>
  );
};

export default HeaderMedia;

const VideoBlock = ({
  videoUrl,
  mediaHeight,
  videoPoster,
}: {
  videoUrl: string;
  videoPoster: string;
  mediaHeight: string;
}) => (
  <div className="flex justify-center items-center lg:max-w-[80%] mx-auto" style={{ height: mediaHeight }}>
    <video
      className={cn("object-contain h-full")}
      style={{ height: mediaHeight }}
      controls={true}
      preload="auto"
      playsInline={true}
      poster={videoPoster}
    >
      <source src={videoUrl} type="video/mp4" />
    </video>
  </div>
);

const ImageBlock = ({
  imageUrl,
  imageAlt,
  placeholder,
}: {
  imageUrl: string;
  imageAlt: string;
  placeholder: string;
}) => (
  <div className="relative w-full lg:max-w-[80%] mx-auto h-full">
    <Image
      src={imageUrl}
      alt={imageAlt}
      className="object-contain w-full"
      fill
      sizes="(max-width: 1024px) 100vw, 1024px"
      placeholder="blur"
      blurDataURL={placeholder}
    />
  </div>
);
