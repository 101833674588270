/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import CustomFormLabel from "@/components/CustomFormLabel";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";

const SiteInput = ({
  label,
  setSelectedPlace,
  deliveryAddressBlur,
  error,
  errorMsg,
  handleDeliveryAddressOnChange,
  placeholder,
}: {
  label: string;
  placeholder: string;
  errorMsg?: string;
  setSelectedPlace: React.Dispatch<React.SetStateAction<any>>;
  deliveryAddressBlur: (e: FocusEvent<HTMLInputElement>) => void;
  handleDeliveryAddressOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["name", "formatted_address"], // "geometry"
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      setSelectedPlace(placeAutocomplete.getPlace());
    });
  }, [setSelectedPlace, placeAutocomplete]);

  return (
    <div className="flex flex-col flex-1">
      <CustomFormLabel label={label} className={cn(error && "text-red-500")} />
      <Input
        type="text"
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleDeliveryAddressOnChange}
        onBlur={deliveryAddressBlur}
        autoComplete={"off"}
        aria-label="enter location"
        aria-autocomplete="none"
        list="autocompleteOff"
        aria-haspopup="false"
        autoCorrect="off"
      />
      {error && <p className=" text-14 font-[400] text-red-500">{errorMsg}</p>}
    </div>
  );
};

export default SiteInput;
