/* eslint-disable @typescript-eslint/no-explicit-any */
export const INDUSTRIES_ARRAY = [
  "industries-dropdown-option-1-title",
  "industries-dropdown-option-2-title",
  "industries-dropdown-option-3-title",
  "industries-dropdown-option-4-title",
  "industries-dropdown-option-5-title",
  "industries-dropdown-option-6-title",
  "industries-dropdown-option-7-title",
];

export const INDUSTRY_DESCRIPTION_MAP: { [key: string]: string } = {
  "industries-dropdown-option-1-title": "industries-dropdown-option-1-description",
  "industries-dropdown-option-2-title": "industries-dropdown-option-2-description",
  "industries-dropdown-option-3-title": "industries-dropdown-option-3-description",
  "industries-dropdown-option-4-title": "industries-dropdown-option-4-description",
  "industries-dropdown-option-5-title": "industries-dropdown-option-5-description",
  "industries-dropdown-option-6-title": "industries-dropdown-option-6-description",
};
// export const durationArray = ["1month", "2months", "3-5months", "6-11months", "+12months", "purchaseUnit"];

export const DURATION_ARRAY = [
  "duration-option-1",
  "duration-option-2",
  "duration-option-3",
  "duration-option-4",
  "duration-option-5",
  "duration-option-6",
];

export const NEW_CARDS = [
  {
    img: "work-collaborate-image",
    title: "work-collaborate-title",
    children: ["work-collaborate-option-1", "work-collaborate-option-2", "work-collaborate-option-3"],
    description: "work-collaborate-description",
    label: "solar-power-available-label",
  },
  {
    img: "store-secure-image",
    title: "store-secure-title",
    children: ["store-secure-option-1", "store-secure-option-2", "store-secure-option-3"],
    description: "store-secure-description",
    label: "solar-power-available-label",
  },

  {
    img: "educate-train-image",
    title: "educate-train-title",
    children: ["educate-train-option-1", "educate-train-option-2", "educate-train-option-3"],
    description: "educate-train-description",
  },
  {
    img: "enter-entertain-host-image",
    title: "enter-entertain-host-title",
    children: ["enter-entertain-host-option-1", "enter-entertain-host-option-2", "enter-entertain-host-option-3"],
    description: "enter-entertain-host-description",
  },
];

export const communicationMethods = [
  ["email", "sms"],
  ["telephone", "all"],
];

export const SPACE_MAPPING: { [key: string]: string } = {
  workCollaborate: "Work & Collaborate",
  storeSecure: "Sore & Secure",
  educateTrain: "Educate & Train",
  entertainHost: "Entertain & Host",
  "small-workCollaborate": "Small (<5 people / 160-256 sq ft)",
  "medium-workCollaborate": "Medium (5-10 people / 256-1140 sq ft)",
  "large-workCollaborate": "Large (+10 people / 1140 sq ft)",
  "small-storeSecure": "Dry Storage / Containers",
  "medium-storeSecure": "Cold Storage",
  "large-storeSecure": "Large Format / Clearspan",
  "small-entertainHost": "Small (<250 people)",
  "medium-entertainHost": "Medium (251-500 people)",
  "large-entertainHost": "Large (+500 people)",
  "small-educateTrain": "Small (<20 people)",
  "medium-educateTrain": "Medium (21-48 people)",
  "large-educateTrain": "Large (+48 people)",
};

export interface GeocodingResponse {
  results: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    formatted_address: string;
    address_components: any;
  }[];
  status: string;
}
