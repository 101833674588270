/* eslint-disable @next/next/no-img-element */
"use client";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { cn } from "@/lib/utils";
import { AppIconButton } from "../buttons/AppIconButton";

import Image from "next/image";
import { ChevronRight } from "lucide-react";

export interface IImageTextBlock {
  id: string;
  image: string;
  placeholder: string;
  imgAlt: string;
  heading: string;
  subheading: string;
  mediaAlign: string;
  ctaLabel: string;
  ctaLink: string;
}

const IndustryCategoryBlock: React.FC<IImageTextBlock> = ({
  id,
  image,
  placeholder,
  imgAlt,
  heading,
  subheading,
  mediaAlign,
  ctaLabel,
  ctaLink,
}) => {
  return (
    <ContentLayout
      id={id}
      bgColorClass={"bg-white"}
      innerClass={cn(
        // "sm:pb-0 pb-0",
        mediaAlign === "Left"
          ? "lg:pl-0 lg:pr-8 xl:pl-0 xl:pr-[80px] 2xl:pl-[104px] 2xl:pr-[104px] overflow-hidden"
          : "lg:pr-0 lg:pl-8 xl:pr-0 xl:pl-[80px] 2xl:pr-[104px] 2xl:pl-[104px] overflow-hidden"
      )}
    >
      <div className="md:grid-cols-12 xl:gap-10 lg:gap-6 md:justify-center grid grid-cols-4 gap-10">
        {mediaAlign === "Left" && (
          <div className="md:col-span-7 relative flex col-span-4 aspect-[16/9]">
            <Image
              src={image}
              alt={imgAlt}
              sizes="(max-width: 768px) 100vw, 800px"
              placeholder="blur"
              blurDataURL={placeholder}
              fill
              className="object-cover w-full aspect-[16/9]"
            />
          </div>
        )}

        <div
          className={cn(
            "col-span-4 md:col-span-5 xxl:col-span-4 flex flex-col md:justify-center",
            mediaAlign !== "Left" && "order-last md:order-first"
          )}
        >
          <div className="border-royal-blue pl-8 border-l-2">
            <AppText className="mt-2 mb-4" type="HEADLINE_SMALL">
              {heading}
            </AppText>
            <AppText className="mt-2 mb-8" type="BODY_SMALL">
              {subheading}
            </AppText>

            <AppIconButton
              intent="ghost"
              iconOnRightSide
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              label={ctaLabel}
              link={ctaLink}
            />
          </div>
        </div>

        {mediaAlign !== "Left" && (
          <div className="md:col-span-7 relative flex items-center col-span-4 aspect-[16/9]">
            <Image
              src={image}
              alt={imgAlt}
              sizes="(max-width: 768px) 100vw,(max-width: 1536px), 60vw, 800px"
              placeholder="blur"
              blurDataURL={placeholder}
              fill
              className="object-cover w-full aspect-[16/9]"
            />
          </div>
        )}
      </div>
    </ContentLayout>
  );
};

export default IndustryCategoryBlock;
