/* eslint-disable @typescript-eslint/no-explicit-any */

import Image from "next/image";
import React from "react";

import AppText from "@/components/AppText";
import { cn } from "@/lib/utils";
import { IDict } from "@/components/constants";

export const FormCardRadio = ({
  title,
  img,
  updateRadioButtons,
  options,
  radioCardsGroupValue,
  description,
  label,
  formCopy,
}: {
  title: string;
  description: string;
  formCopy: IDict;
  img: string;
  outfit?: boolean;
  options: string[];
  updateRadioButtons: (group: string, value: string, del: boolean) => void;
  radioCardsGroupValue: string | undefined;
  label?: string;
}) => {
  const handleRadioClick = (e: any) => {
    const value = e.target.value;

    if (value) {
      if (value === radioCardsGroupValue) {
        // Check if the value is the same as the current value

        updateRadioButtons(title, value, true);
      } else {
        updateRadioButtons(title, value, false);
      }
    } else {
      if (radioCardsGroupValue) {
        updateRadioButtons(title, radioCardsGroupValue, true);
      }
    }
  };

  return (
    <div data-focus-mark="card-mark" className="bg-navy-blue-10 lg:col-span-3 flex flex-col col-span-4 p-4">
      {label && (
        <AppText type="BODY_SMALL_EMPHASIS" className="text-royal-blue text-end font-[600] uppercase">
          {label}
        </AppText>
      )}

      {/** add support for blur when refactoring to contentful data */}
      <Image
        src={img}
        sizes="100vw"
        width={0}
        height={0}
        className="flex-1 object-cover w-full max-w-[220px] mx-auto"
        alt={formCopy[title]}
      />

      <div className="flex flex-col flex-1 mb-5">
        <AppText type="SUB_HEADING_SMALL" className="text-wrap text-18">
          {formCopy[title]}
        </AppText>
        <AppText type="BODY_SMALL_EMPHASIS" className="text-wrap font-[400]">
          {description}
        </AppText>
      </div>
      <fieldset className="flex flex-col" id={title}>
        <legend className="hidden">{title}</legend>

        {options.map((item) => {
          const id = `radio-group-${item}`;

          return (
            <div role="radiogroup" key={item} className="flex flex-row items-center py-[2px] space-x-3 space-y-0">
              <input
                id={id}
                type="radio"
                checked={radioCardsGroupValue === item}
                name={title}
                value={item}
                onChange={() => {}}
                onClick={handleRadioClick}
                className="min-w-4 focus-visible:ring-blue-500/50 ring-2 ring-transparent focus-visible:outline-none w-4 h-4 outline-none"
              />
              <label className="font-[400] font-saira text-15" htmlFor={id}>
                {formCopy[item] || "-"}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export const FormCardCheckbox = ({
  title,
  label,
  img,
  description,
  checkboxes,
  handleCheckboxes,
  checkBoxCard,
  formCopy,
}: {
  title: string;
  label: string;
  img: string;
  description: string;
  formCopy: IDict;
  checkboxes: any;
  handleCheckboxes: (item: string, checked: boolean) => void;
  checkBoxCard: any;
}) => {
  return (
    <div data-focus-mark="card-mark" className="bg-navy-blue-10 lg:col-span-3 flex flex-col col-span-4 p-4">
      {label && (
        <AppText type="BODY_SMALL_EMPHASIS" className="text-royal-blue text-end font-[600] uppercase">
          {label}
        </AppText>
      )}
      <Image
        src={img}
        sizes="100vw"
        width={0}
        height={0}
        className="flex-1 object-cover w-full max-w-[220px] mx-auto"
        alt={title}
      />

      <div className="flex flex-col flex-1 mb-5">
        <AppText type="SUB_HEADING_SMALL" className="text-wrap text-18">
          {title}
        </AppText>
        <AppText type="BODY_SMALL_EMPHASIS" className="text-wrap font-[400]">
          {description}
        </AppText>
      </div>

      <fieldset className="checkBoxContainer flex flex-col">
        <legend className="hidden">radio buttons</legend>
        {checkboxes.map((item: string) => {
          return (
            <div key={item} className="flex flex-row items-center py-[2px] space-x-3 space-y-0">
              <input
                id={item}
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxes(item, e.target.checked)}
                checked={checkBoxCard[item] || false}
                className={cn(
                  "min-w-4 shrink-0 bg-[#e6e9ef] cursor-pointer border-black border-[1px] w-4 h-4 focus-visible:ring-blue-500/50 ring-2 ring-transparent outline-none focus-visible:outline-none",
                  checkBoxCard[item] ? "accent-black" : "appearance-none"
                )}
              ></input>
              <label htmlFor={item} className="space-y-1 leading-none font-[400] font-saira text-15">
                {formCopy[item]}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};
