import React from "react";
import AppText from "@/components/AppText";
import { AppIconButton } from "@/components/buttons/AppIconButton";
import { cn } from "@/lib/utils";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface ArrowPaginationProps {
  currentPage: number;
  pageCount: number;
  onPrevPageClick: () => void;
  onNextPageClick: () => void;
  className?: string;
  type?: string;
  bg?: "dark" | "light" | undefined;
}

const ArrowPagination: React.FC<ArrowPaginationProps> = ({
  currentPage,
  pageCount,
  onPrevPageClick,
  onNextPageClick,
  className,
  type,
  bg,
}) => {
  const renderArrows = () => (
    <>
      <AppIconButton
        onClick={onPrevPageClick}
        disabled={currentPage === 1}
        icon={<ArrowLeft size={20} aria-label="arrow left icon" />}
        intent={"secondary"}
        title={"previous"}
        className={"w-[48px] h-[48px]"}
        bg={bg}
      />
      <AppIconButton
        disabled={currentPage === pageCount}
        onClick={onNextPageClick}
        icon={<ArrowRight size={20} aria-label="arrow right icon" />}
        intent={"secondary"}
        title={"next"}
        className={"w-[48px] h-[48px]"}
        bg={bg}
      />
    </>
  );

  return (
    <div className={cn("flex items-center gap-5 pointer-events-auto", className)}>
      <AppText
        type={"BODY_SMALL"}
        className={cn(
          type === "caseStudy" ? "lg:hidden block text-white" : "",
          bg === "dark" ? "text-white" : "text-black"
        )}
      >
        {currentPage}/{pageCount}
      </AppText>

      {renderArrows()}

      {type === "caseStudy" && (
        <AppText type={"BODY_SMALL"} className={cn("lg:block hidden", bg === "dark" ? "text-white" : "text-black")}>
          {currentPage}/{pageCount}
        </AppText>
      )}
    </div>
  );
};

export default React.memo(ArrowPagination);
