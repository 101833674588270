/* eslint-disable @typescript-eslint/no-explicit-any */

import { useFormData } from "@/store/servicesFormsStore";
import FormRequestSupportStep1 from "./FormRequestSupportStep1";
import FormRequestSupportStep2 from "./FormRequestSupportStep2";
import FormRequestSupportStep3 from "./FormRequestSupportStep3";
import StepperServices from "./StepperServices";

const FormRequestSupport = ({
  formModel: { formTabs, STEPS_TAB_LABELS },
}: {
  formModel: { formTabs: any; STEPS_TAB_LABELS: string[] };
}) => {
  const { step } = useFormData();

  return (
    <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 pt-12 bg-white">
      <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 py-1 bg-white">
        <StepperServices className="bg-white" steps={STEPS_TAB_LABELS} currentStepIndex={step} />
      </div>

      {/* Stepper Form */}

      {step === 1 ? (
        <FormRequestSupportStep1 formData={formTabs[0]} />
      ) : step === 2 ? (
        <FormRequestSupportStep2 formData={formTabs[1]} />
      ) : step === 3 ? (
        <FormRequestSupportStep3 formData={formTabs[2]} />
      ) : null}
    </div>
  );
};

export default FormRequestSupport;
