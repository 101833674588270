import React from "react";
import { EmblaOptionsType } from "embla-carousel";
import { usePrevNextButtons } from "./emblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./emblaCarouselDotButton";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";

type PropType = {
  slides: { url: string; alt: string; placeholder: string }[];
  options?: EmblaOptionsType;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <div className="sm:px-4 lg:px-0 relative px-0">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="backface-hidden touch-pan-y touch-pinch-zoom flex -ml-4">
          {slides.map((slide, idx) => (
            <div className="pl-4 min-w-0 flex-[0_0_251px] sm:flex-[0_0_100%] lg:flex-[0_0_938px] relative" key={idx}>
              <Image
                src={slide.url}
                alt={slide.alt || "image"}
                sizes="100vw"
                width={0}
                height={0}
                placeholder="blur"
                blurDataURL={slide.placeholder}
                className="object-cover w-full h-[166px] lg:h-[525px] sm:h-[307px] "
              />
            </div>
            // <div className="pl-4 min-w-0 flex-[0_0_251px] sm:flex-[0_0_100%] lg:flex-[0_0_938px]" key={idx}>
            //   <Image
            //     quality={90}
            //     priority
            //     src={slide.url}
            //     alt={slide.alt || "image"}
            //     sizes="100vw"
            //     width={0}
            //     height={0}
            //     className="object-cover w-full h-[166px] lg:h-[525px] sm:h-[307px] "
            //   />
            // </div>
          ))}
        </div>
      </div>

      <div className="absolute top-[calc(50%_-_16px)] left-0 flex justify-center w-full">
        <div className="flex justify-between w-[251px] sm:w-full lg:w-[938px] px-3 sm:px-5 lg:px-3">
          <AppIconButton
            onClick={onPrevButtonClick}
            icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
            intent={"secondary"}
            size={"md"}
            title={"previous"}
            className={
              "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
            }
          />
          <AppIconButton
            onClick={onNextButtonClick}
            icon={<ChevronRight size={20} aria-label="chevron right icon" />}
            intent={"secondary"}
            size={"md"}
            title={"next"}
            className={
              "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
            }
          />
        </div>
      </div>

      <div className="flex justify-center gap-2 py-6">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={cn("w-2 m-2 h-2 bg-black-20 rounded-full", index === selectedIndex && "bg-navy-blue")}
          />
        ))}
      </div>
    </div>
  );
};

export default EmblaCarousel;
