/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";

import getDataFromCookie from "@/components/utmData/utmHelper";

// ! todo: make env variables read from server
const OwnerId = "3D00G8c000006wyqj";
const RequesterC = "0058c00000CpVJOAA3";

export const preparePayload = async (
  values: any,
  radioCards: any,
  checkBoxCard: { [key: string]: boolean },
  formCopy: { [key: string]: string | undefined },
  siteAddress: string
) => {
  const temporaryData: { [key: string]: string }[] = [
    {
      Key: "",
      Name: "Product Type - Size",
      Value: radioCards["work-collaborate-title"]
        ? `Work & Collaborate - ${formCopy[radioCards["work-collaborate-title"]]}`
        : null,
    },
    {
      Key: "",
      Name: "Product Type - Size",
      Value: radioCards["educate-train-title"]
        ? `Educate & Train - ${formCopy[radioCards["educate-train-title"]]}`
        : null,
    },
    {
      Key: "",
      Name: "Product Type - Size",
      Value: radioCards["enter-entertain-host-title"]
        ? `Entertain & Host - ${formCopy[radioCards["enter-entertain-host-title"]]}`
        : null,
    },
    {
      Key: "",
      Name: "Duration",
      Value: formCopy[values.quote_duration],
    },
    {
      Key: "",
      Name: "Delivery Date",
      Value: format(values.deliveryDate, "MM/dd/yyyy"),
    },
    {
      Key: "",
      Name: "Interest",
      Value: values.quote_anythingElse,
    },
    {
      Key: "",
      Name: "Industry",
      Value:
        values.quote_industries !== "industries-dropdown-option-7-title"
          ? formCopy[values.quote_industries]
          : values.quote_other_industries,
    },
    {
      Key: "",
      Name: "Product Industry",
      Value:
        values.quote_industries !== "industries-dropdown-option-7-title"
          ? formCopy[values.quote_industries]
          : values.quote_other_industries,
    },
  ];

  const boxesValues = [];

  if (checkBoxCard["store-secure-option-1"]) {
    boxesValues.push(formCopy["store-secure-option-1"]);
  }

  if (checkBoxCard["store-secure-option-2"]) {
    boxesValues.push(formCopy["store-secure-option-2"]);
  }

  if (checkBoxCard["store-secure-option-3"]) {
    boxesValues.push(formCopy["store-secure-option-3"]);
  }

  if (boxesValues.length > 0) {
    const join = boxesValues.join("; ");
    temporaryData.push({
      Key: "",
      Name: "Product Type - Size",
      Value: `Store & Secure - ${join}`,
    });
  }

  const payload = {
    Type: 100,
    Token: "ed548cf1-3106-461b-ba68-fa98b3f5bd0a",
    Campaign: "WillScot Website",
    IsDisplayAd: false,
    FirstName: values.FirstName,
    LastName: values.LastName,
    Company: values.quote_company_name,
    SiteAddress1: "N/A",
    // Address1: "N/A",
    // City: "N/A",
    // State: "N/A",
    // Zip: "N/A",
    // Country: "N/A",
    Site_Address__c: "N/A",
    Site_City__c: "N/A",
    Site_State__c: "N/A",
    Site_Zip__c: "N/A",
    Phone: values.Phone,
    Fax: "",
    Email: values.Email,
    SendDebugEmail: false,
    DebugSource: null,
    Industry:
      values.quote_industries !== "industries-dropdown-option-7-title"
        ? formCopy[values.quote_industries]
        : values.quote_other_industries,
    Requester__c: RequesterC,
    OwnerId,
    SiteZip: "N/A",
    Data: temporaryData.filter((item: any) => !!item.Value),
    // LeadQA_Additional_Information__c: values.quote_anythingElse,
    LeadQA_Requested_Delivery_Date__c: "",
    // Email_Opt_In__c: !values.receiveUpdates,
    // Comments: "These are the comments",
    // Comments__c: values.quote_anythingElse, // textarea mapped to LeadQA_Additional_Information__c
    // Purchase__c: recommendedUnitsSwitch,
    // LeadQA_Requested_Delivery_Date__c: values.deliveryDate,
    // Delivery_City__c: "N/A",
    // Delivery_Postal_Code__c: "N/A",
    // Delivery_State__c: "N/A",
    // Delivery_Street__c: "N/A",
    // Comments__c: values.quote_anythingElse, // textarea mapped to LeadQA_Additional_Information__c
    // Purchase__c: recommendedUnitsSwitch,
    // QuoteId: "123ABC",
    // Category: "TRADITIONAL_PRODUCT",
    // JobTitle: "",
    ga_campaign: "",
    ga_content: "",
    ga_medium: "",
    ga_source: "",
    ga_term: "",
  };

  payload.SiteAddress1 = siteAddress || "N/A";
  payload.Site_Address__c = siteAddress || "N/A";
  payload.Site_City__c = values.Site_City__c || "N/A";
  payload.Site_State__c = values.Site_State__c || "N/A";
  payload.Site_Zip__c = values.Site_Zip__c || "N/A";
  payload.SiteZip = values.Site_Zip__c || "N/A";

  // Send UTM Data
  const utmDataFromCookie = getDataFromCookie();

  if (utmDataFromCookie.utm_campaign) {
    payload.ga_campaign = utmDataFromCookie.utm_campaign;
  }
  if (utmDataFromCookie.utm_content) {
    payload.ga_content = utmDataFromCookie.utm_content;
  }
  if (utmDataFromCookie.utm_medium) {
    payload.ga_medium = utmDataFromCookie.utm_medium;
  }
  if (utmDataFromCookie.utm_source) {
    payload.ga_source = utmDataFromCookie.utm_source;
  }
  if (utmDataFromCookie.utm_term) {
    payload.ga_term = utmDataFromCookie.utm_term;
  }

  return payload;
};
