/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import { ChevronRight, X } from "lucide-react";

import Pagination from "../Pagination";
import { ITEMS_PER_PAGE } from "../constants";
import ContentLayout from "../layouts/ContentLayout";
import CustomLink from "../CustomLink";
import Image from "next/image";
import AppText from "../AppText";
import { AppIconButton } from "../buttons/AppIconButton";

import Multiselect, { IOption } from "../ui/multiselect";
import { parseDocuments } from "@/lib/parseDocuments";

interface IListItem {
  id: string;
  title: string;
  image: string;
  placeholder: string;
  ctaLink: string;
  ctaLabel: string;
}

interface ICaseStudy {
  id?: string;
  dict: { [key: string]: string };
  preview: boolean;
  industries: IOption[];
  topics: IOption[];
  list: IListItem[];
  totalSum: number;
  storybook?: boolean;
  paddingBottom?: string;
}

const Badge = ({ item, clearBadge }: { item: IOption; clearBadge: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <div className="bg-black-5 flex items-center gap-3 pl-4 rounded">
      <AppText type="LABEL_EMPHASIS" className="break-keep whitespace-nowrap py-1">
        {item.name}
      </AppText>
      <button
        type="button"
        aria-label="clear badge"
        id="button-documents-and-resources-list"
        onClick={clearBadge}
        className="hover:bg-black-20 focus:outline-dijon h-full px-2 py-1 rounded-tr rounded-br outline-none"
      >
        <X size={16} aria-label="x icon" />
      </button>
    </div>
  );
};

const DocumentsAndResourcesList: React.FC<ICaseStudy> = ({
  dict,
  preview,
  industries,
  topics,
  list,
  totalSum,
  id,
  storybook,
  paddingBottom,
}) => {
  const [cards, setCards] = useState<any>(list);
  const [selectedIndustries, setSelectedIndustries] = useState<any>([]);
  const [selectedTopics, setSelectedTopics] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(totalSum);
  const [loading, setLoading] = useState(false);
  const scrollRef: any = useRef();

  const doSequential = useRef(false);

  const lastPage = Math.ceil(total / ITEMS_PER_PAGE);

  useEffect(() => {
    setTimeout(() => {
      doSequential.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    if (!doSequential.current) return;

    const top = scrollRef?.current?.offsetTop;
    window.scrollTo({ top: top - 100, behavior: "smooth" });

    (async () => {
      try {
        setLoading(true);
        const _ind = selectedIndustries.map((ind: { name: string; value: string }) => ind.value).join(",");
        const _top = selectedTopics.map((top: { name: string; value: string }) => top.value).join(",");

        const res = await fetchContentful(
          _ind,
          _top,
          (currentPage - 1) * ITEMS_PER_PAGE,
          dict.read_more,
          dict.download
        );

        setTotal(res.total);
        setCards(res.parsed);
      } catch (err) {
        console.log("Error occurred when fetching filters");
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, selectedIndustries, selectedTopics, preview, dict]);

  const handleClearAll = () => {
    setCurrentPage(1);
    setSelectedIndustries([]);
    setSelectedTopics([]);
  };

  const clearBadge = (value: string) => {
    const ind = selectedIndustries.filter((i: IOption) => i.value !== value);
    const top = selectedTopics.filter((t: IOption) => t.value !== value);
    setCurrentPage(1);
    setSelectedIndustries(ind);
    setSelectedTopics(top);
  };

  const alSelections = [...selectedIndustries, ...selectedTopics];

  const handleSearchString = () => {
    // combination of industries and topics
    let res = "";

    const ind = selectedIndustries.length ? "selected industries" : "";
    const top = selectedTopics.length ? "selected topics" : "";

    if (ind && top) {
      res = `${ind} ${dict.and} ${top}`;
    } else if (ind) {
      res = ind;
    } else {
      res = top;
    }

    return res;
  };

  const handleSelectedIndustries = (selected: IOption[]) => {
    setCurrentPage(1);
    setSelectedIndustries(selected);
  };

  const handleSelectedTopics = (selected: IOption[]) => {
    setCurrentPage(1);
    setSelectedTopics(selected);
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id} paddingBottom={paddingBottom}>
      {!storybook && (
        <div className="sm:gap-6 flex flex-col w-full gap-4 pb-8" ref={scrollRef}>
          <div className="sm:flex-row lg:gap-10 lg:items-center flex flex-col justify-between gap-6">
            <div className="sm:flex-row sm:gap-10 sm:items-center z-10 flex flex-col w-full gap-6">
              {industries && industries.length && (
                <div className="w-full sm:max-w-[386px]">
                  <Multiselect
                    selectedItems={selectedIndustries}
                    setSelectedItems={(selected: IOption[]) => handleSelectedIndustries(selected)}
                    allLabel="All Industries"
                    selectedLabel="Industries"
                    allItems={industries}
                  />
                </div>
              )}

              {topics && topics.length && (
                <div className="w-full sm:max-w-[386px]">
                  <Multiselect
                    selectedItems={selectedTopics}
                    setSelectedItems={(selected: IOption[]) => handleSelectedTopics(selected)}
                    allLabel="All Topics"
                    selectedLabel="Topics"
                    allItems={topics}
                  />
                </div>
              )}
            </div>

            {!!alSelections.length && (
              <AppIconButton
                intent="ghost"
                label={dict.clearAll}
                icon={<X size={16} aria-label="x icon" />}
                iconOnRightSide
                bg="light"
                onClick={handleClearAll}
              />
            )}
          </div>

          <div className="flex flex-wrap w-full min-w-0 gap-3 pt-1 text-sm">
            {!!alSelections.length &&
              alSelections.map((item: IOption) => (
                <Badge key={item.value} item={item} clearBadge={() => clearBadge(item.value)} />
              ))}
          </div>

          <div className="flex text-[14px]">
            {total > 0 ? (
              <span>
                {total} {total === 1 ? dict.result : dict.results}
              </span>
            ) : null}
          </div>
        </div>
      )}
      {loading ? (
        <>
          <div className="flex items-center">
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
          </div>
        </>
      ) : !cards.length && !total ? (
        <div className="flex flex-col">
          <AppText type="HEADLINE_SMALL" as="h2" className="mb-4">
            {`${dict.sorryNoResults}  ${handleSearchString()}`}
          </AppText>
          <AppText type="BODY_MEDIUM" className="text-black-80 mb-14">
            {dict.tryDifferentDocFilters}
          </AppText>
        </div>
      ) : (
        <div className="lg:grid-cols-12 sm:gap-6 xl:gap-10 grid grid-cols-4 gap-2">
          {cards.map((doc: any) => (
            <CustomLink
              key={doc.id}
              href={doc.articleUrl}
              lang={"en"}
              className={`hover:cursor-pointer focus-visible:outline-dijon flex flex-col 
            overflow-hidden transition h-[100%] group sm:col-span-2 lg:col-span-4 col-span-4`}
            >
              <div className={`relative w-full overflow-hidden aspect-[16/9]`}>
                <Image
                  className="object-cover w-full group-hover:scale-105 transition aspect-[16/9]"
                  sizes="(max-width: 640px) 100vw,(max-width: 1536px) 50vw, 450px"
                  fill
                  placeholder="blur"
                  blurDataURL={doc.placeholder}
                  src={doc.imgSrc || ""}
                  alt={doc.imgAlt || ""}
                />
              </div>
              <div className="flex flex-col items-start flex-1 py-4">
                <AppText className="line-clamp-2 mb-4" type="SUB_HEADING_MEDIUM">
                  {doc.title}
                </AppText>

                <AppIconButton
                  tabIndex={-1}
                  intent="ghost"
                  label={doc.buttonLabel}
                  icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                  iconOnRightSide
                  className="mt-auto"
                />
              </div>
            </CustomLink>
          ))}
        </div>
      )}
      {!loading && total > 12 && (
        <div className="flex justify-center px-6 py-[48px] mt-8">
          <Pagination currentPage={currentPage} lastPage={lastPage} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </ContentLayout>
  );
};

export default DocumentsAndResourcesList;

const fetchContentful = async (
  industryIDs: string,
  topicIDs: string,
  skip: number,
  read_more: string,
  download: string
) => {
  let suffixUrl: string = "";

  if (!!topicIDs && !!industryIDs) {
    suffixUrl = `&fields.associatedIndustry.sys.id[in]=${industryIDs}&fields.topic[in]=${topicIDs}`;
  } else if (topicIDs) {
    suffixUrl = `&fields.topic[in]=${topicIDs}`;
  } else if (industryIDs) {
    suffixUrl = `&fields.associatedIndustry.sys.id[in]=${industryIDs}`;
  } else {
    suffixUrl = "";
  }

  const payload = {
    suffixUrl,
    skip,
    limit: 12,
  };

  try {
    const data = await fetch("/api/contentful/documents", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const res = await data.json();

    if (res.error) {
      return { parsed: [], total: 0, error: res.error.message };
    }

    const parsed = await parseDocuments(res.items || [], read_more, download);

    return { parsed, total: res.total };
  } catch (error: any) {
    console.log(error);
    return { parsed: [], total: 0, error: error.message };
  }
};
