import React from "react";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import AppText from "@/components/AppText";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { Info24 } from "@/assets/Icons";
import IRequestServiceForm from "./RequestServiceForm";
import { AppButton } from "@/components/buttons/AppButton";
import { SERVICE_OPTIONS } from "./constants";
import { CustomFormMessage } from "@/components/CustomFormMessage";

interface IOption {
  title: string;
  description: string;
  value: string;
  selected: boolean;
  onClick?: (value: string) => void;
  onToggle?: (value: string | undefined) => void;
}

const selectServiceSchema = z.object({
  Request_Type__c: z.string().min(1, {
    message: "selectOneOfTheServices",
  }),
});
export type SelectServiceSchema = z.infer<typeof selectServiceSchema>;

const SelectServiceOption: React.FC<IOption> = ({ title, description, value, selected, onClick, onToggle }) => {
  return (
    <div
      className={cn("flex flex-col px-6 py-8 bg-navy-blue-10 gap-4 cursor-pointer", selected && "bg-navy-blue-20")}
      onClick={() => {
        onClick && onClick(value);
        onToggle && onToggle((!selected && value) || undefined);
      }}
    >
      <div className="flex flex-row justify-start gap-2">
        <Info24 className="bg-royal-blue w-9 h-9 shrink-0 fill-white p-1 rounded-full" />
        <AppText type="TITLE_MEDIUM">{title}</AppText>
      </div>
      <div>
        <AppText type="BODY_MEDIUM">{description}</AppText>
      </div>
    </div>
  );
};

const SelectServiceForm: React.FC<IRequestServiceForm> = ({ onSubmitSuccess, formData, formCopy }) => {
  const form = useForm<z.infer<typeof selectServiceSchema>>({
    resolver: zodResolver(selectServiceSchema),
    values: formData as SelectServiceSchema,
    defaultValues: {
      Request_Type__c: "",
    },
  });

  const onSubmit = (values: z.infer<typeof selectServiceSchema>) => {
    onSubmitSuccess(values);
  };

  return (
    <Form {...form}>
      <form
        className="lg:col-span-10 lg:col-start-2 lg:px-10 pb-[56px] col-span-4 px-0 bg-white"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
          <AppText type="TITLE_MEDIUM">{formCopy["page-1-title"]}</AppText>
          <FormField
            control={form.control}
            name="Request_Type__c"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="sm:grid-cols-2 grid gap-6 space-y-0" ref={field.ref}>
                <FormControl>
                  <>
                    {SERVICE_OPTIONS.map((option: { [key: string]: string }) => (
                      <SelectServiceOption
                        key={option.key}
                        title={formCopy[option.title]}
                        description={formCopy[option.description]}
                        value={option.key}
                        selected={option.key === field.value}
                        onToggle={field.onChange}
                      />
                    ))}
                  </>
                </FormControl>
                {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
              </FormItem>
            )}
          />
        </div>
        <div className="bg-white flex flex-row-reverse flex-wrap justify-between px-[40px]">
          <AppButton intent="primary" label={formCopy["next-cta"]} className="px-10" type="submit" />
        </div>
      </form>
    </Form>
  );
};

export default SelectServiceForm;
