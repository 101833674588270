"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ChevronRight } from "lucide-react";
import { AppIconButton } from "./buttons/AppIconButton";

interface IGetInTouchClientChatBlock {
  blockTwoCtaLabel: string;
  blockTwoCtaLink: string;
}

const GetInTouchClientChatBlock = ({ blockTwoCtaLabel, blockTwoCtaLink }: IGetInTouchClientChatBlock) => {
  const isSupposedToOpenChatInsteadOfLink =
    blockTwoCtaLabel === "Start a chat" ||
    blockTwoCtaLink === "#open-integrated-chat-box" ||
    blockTwoCtaLink === "{{chat}}";
  const cardCtaCustomLink = isSupposedToOpenChatInsteadOfLink ? undefined : blockTwoCtaLink;
  const onClickHandler = isSupposedToOpenChatInsteadOfLink
    ? (e: any) => {
        e.preventDefault();
        console.log(`Opening: Chat`);
        const button = document.querySelector(".helpButtonEnabled") as HTMLButtonElement;
        button?.click();
      }
    : undefined;

  return (
    <div>
      <AppIconButton
        intent="ghost"
        iconOnRightSide
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        label={blockTwoCtaLabel}
        link={cardCtaCustomLink}
        onClick={onClickHandler}
        bg={"dark"}
      />
    </div>
  );
};

export default GetInTouchClientChatBlock;
