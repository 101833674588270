/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { isMobilePhone } from "validator";
import { useParams, useRouter } from "next/navigation";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { APIProvider } from "@vis.gl/react-google-maps";
import { fromAddress, OutputFormat, setDefaults } from "react-geocode";
import { toast } from "sonner";

import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { AppButton } from "@/components/buttons/AppButton";
import { Button as CNButton } from "@/components/ui/button";
import AppText from "@/components/AppText";
import { cn } from "@/lib/utils";
import { Textarea } from "@/components/ui/textarea";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import SiteInput from "./SiteInput";
import { BorderNumber } from "@/components/BorderNumber";
import { parseData, preparePayload } from "./helper";
import CustomFormLabel from "@/components/CustomFormLabel";
import LoadingDots from "@/components/LoadingDots";
import ContentLayout from "@/components/layouts/ContentLayout";
import { CustomFormMessage } from "@/components/CustomFormMessage";
import { GeocodingResponse } from "./constants";

let addressInterval: any;

interface IRquestQuoteForm {
  formCopy: { [key: string]: string };
}

setDefaults({
  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!,
  language: "en",
  outputFormat: OutputFormat.JSON,
});

const formatting_options = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
};

const formatPrice = (price: any) => {
  // const formattedPrice = new Intl.NumberFormat(price, formatting_options);

  // return formattedPrice;

  if (price?.toLocaleString("en-US", formatting_options)) {
    return price.toLocaleString("en-US", formatting_options);
  }

  return "$" + price;
};

const RequestQuoteToBuyForm = ({ formCopy }: IRquestQuoteForm) => {
  const [unitNumber, setUnitNumber] = useState<string | null>(null);
  const [unit, setUnit] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [selectedPlace, setSelectedPlaceBuy] = useState<google.maps.places.PlaceResult | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: 1 } | Record<string, never>>({});
  const [loading, setLoading] = useState(true); // loading unit data

  const siteAddress = useRef("");
  const googlePlacesTouched = useRef(false);

  const { slug } = useParams();

  const router = useRouter();

  const formSchema = z.object({
    your_best_offer: z.coerce.number().optional(),
    FirstName: z.string().min(2, { message: formCopy.min2char }).max(50, { message: formCopy.max50char }),
    LastName: z.string().min(2, { message: formCopy.min2char }).max(50, { message: formCopy.max50char }),
    Email: z.string().email({ message: formCopy.invalidEmail }),
    quote_company_name: z.string().optional(),
    googlePlacesBuy: z.string().min(1, { message: formCopy.required }),
    Phone: z.string().refine(isMobilePhone, {
      message: formCopy.invalidPhone,
    }),
    deliveryDate: z.date({
      required_error: formCopy.required,
    }),
    quote_anythingElse: z.string().max(2000, { message: "Maximum 2000 characters" }).optional(),
    Site_State__c: z.string().min(1, { message: formCopy.required }),
    Site_Zip__c: z.string().min(1, { message: formCopy.required }),
    Site_City__c: z.string().min(1, { message: formCopy.required }),
    Site_Country__c: z.string().min(1, { message: formCopy.required }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      quote_company_name: "",
      googlePlacesBuy: "",
      Phone: "",
      quote_anythingElse: "",
      Site_State__c: "",
      Site_Zip__c: "",
      Site_City__c: "",
      Site_Country__c: "",
    },
    mode: "onBlur",
  });

  useEffect(() => {
    if (unitNumber) {
      fetchUnit(unitNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitNumber]);

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const data = (await fromAddress(selectedPlace?.formatted_address)) as GeocodingResponse;

      let city = "",
        state = "",
        zipCode = "",
        address = "",
        streetNumber = "",
        neighborhood = "",
        country = "";
      const components = data.results[0].address_components || [];
      const formatted_address = data.results[0].formatted_address as string;

      for (const target of components) {
        const types = target.types || [];

        if (types.includes("locality")) {
          city = target.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = target.long_name;
          // const stateData = JSON.stringify({ long: target.long_name, short: target.short_name });
          // localStorage.setItem("siteState", stateData);
        } else if (types.includes("postal_code")) {
          zipCode = target.long_name;
        } else if (types.includes("route")) {
          address = target.long_name;
        } else if (types.includes("neighborhood")) {
          neighborhood = target.long_name;
        } else if (types.includes("street_number")) {
          streetNumber = target.long_name;
        } else if (types.includes("country")) {
          country = target.long_name;
        }
      }

      form.setValue("Site_Country__c", country);
      form.setValue("Site_State__c", state);
      form.setValue("Site_Zip__c", zipCode);
      form.setValue("Site_City__c", city);
      if (address) {
        if (streetNumber) {
          siteAddress.current = `${streetNumber} ${address}`;
        } else {
          siteAddress.current = `${address}`;
        }
      } else if (formatted_address) {
        siteAddress.current = formatted_address;
      } else {
        siteAddress.current = neighborhood;
      }

      setTimeout(() => {
        form.trigger("Site_Country__c");
        form.trigger("Site_State__c");
        form.trigger("Site_Zip__c");
        form.trigger("Site_City__c");
      }, 500);
    }
  };

  useEffect(() => {
    if (slug && slug.length && slug.length === 1) {
      setUnitNumber(decodeURI(slug[0]));
    } else {
      router.push("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    clearInterval(addressInterval);
    if (selectedPlace && selectedPlace?.formatted_address) {
      const tmp = { ...errors };
      delete tmp.selectedPlace;

      setErrors(tmp);
      getSiteData();
      form.setValue("googlePlacesBuy", "valid", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      if (googlePlacesTouched.current) {
        form.setValue("googlePlacesBuy", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setSubmitting(true);

    const payload = await preparePayload(values, unit, siteAddress?.current);

    try {
      const response = await fetch("/api/request-quote-buy", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      // request is valid if data is empty string
      if (data.error || data.message) {
        console.log(data);

        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push("/request-quote-buy/thank-you");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  const fetchUnit = async (unitNumber: string) => {
    setLoading(true);

    try {
      const data = await fetch("/api/contentful/sales-showroom/unit-number", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ unitNumber }),
      });

      const res = await data.json();

      if (res.error || !res.items?.[0]) {
        toast.error("Error fetching data from Contentful");
      } else {
        const _unit = parseData(res.items[0], res.includes?.Entry || []);
        setUnit(_unit);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deliveryAddressBlur = () => {
    googlePlacesTouched.current = true;
  };

  const handleDeliveryAddressOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setSelectedPlaceBuy(null);
    }
  };

  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  return (
    <ContentLayout
      bgColorClass="bg-black-5"
      innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px] "
    >
      <div className="lg:col-span-10 lg:col-start-2 lg:px-10 pb-[56px] pt-[50px] col-span-4 px-6 bg-white">
        <Form {...form}>
          <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
            <input
              autoComplete="new-password"
              aria-label="autocomplete input disabled"
              name="hidden"
              type="text"
              className="hidden"
            ></input>
            <div className=" flex flex-col">
              {/**---------------------------------- block 1 */}
              <div className="flex gap-[18px]">
                <BorderNumber number={1} />
                <div className="flex-1 flex flex-col pb-[60px] gap-[12px]">
                  <AppText type="TITLE_MEDIUM">{formCopy["question-1-title"]}</AppText>

                  {loading ? (
                    <LoadingDots />
                  ) : (
                    <div className="flex flex-col mt-[26px] gap-[18px]">
                      <AppText type="BODY_SMALL">
                        {formCopy["question-1-unit-number"]} : <span className="text-16">{unit?.unitNumber}</span>
                      </AppText>
                      <AppText type="BODY_SMALL">
                        {formCopy["question-1-product-type"]} : <span className="text-16">{unit?.productType}</span>
                      </AppText>
                      <AppText type="BODY_SMALL">
                        {formCopy["question-1-unit-location"]} : <span className="text-16">{unit?.unitLocation}</span>
                      </AppText>
                      <AppText type="BODY_SMALL">
                        {formCopy["question-1-sale-price"]} :{" "}
                        <span className="text-16">{formatPrice(unit?.price)}</span>
                      </AppText>
                    </div>
                  )}

                  {/**---------------------------------- Your Best Offer */}
                  {unit?.obo && (
                    <div className="lg:flex-row flex flex-col gap-6 mt-4">
                      <FormField
                        control={form.control}
                        name="your_best_offer"
                        render={({ field }) => (
                          <FormItem className="flex-1 space-y-0">
                            <CustomFormLabel label={formCopy.yourBestOffer} />
                            <FormControl>
                              <Input
                                placeholder="$000,000"
                                {...field}
                                className="font-saira"
                                aria-describedby={undefined}
                              />
                            </FormControl>

                            <FormMessage className="font-saira text-14 font-[400]" />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className=" flex flex-col">
              {/**---------------------------------- block 1 */}
              <div className="flex gap-[18px]">
                <BorderNumber number={2} />
                <div className="flex-1 flex flex-col pb-[60px] gap-[12px]">
                  <AppText type="TITLE_MEDIUM">{formCopy["question-2-title"]}</AppText>

                  {/**---------------------------------- first name */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="FirstName"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["first-name-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["first-name-placeholder"]}
                              {...field}
                              className="font-saira"
                              disabled={loading}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />

                    {/**---------------------------------- last name */}
                    <FormField
                      control={form.control}
                      name="LastName"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["last-name-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["last-name-placeholder"]}
                              {...field}
                              className="font-saira"
                              disabled={loading}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/**---------------------------------- email */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="Email"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["email-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["email-placeholder"]}
                              {...field}
                              className="font-saira"
                              disabled={loading}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />

                    {/**---------------------------------- phone */}
                    <FormField
                      control={form.control}
                      name="Phone"
                      rules={{
                        validate: (value) =>
                          matchIsValidTel(value ?? "", {
                            onlyCountries: ["US", "CA"],
                          }),
                      }}
                      render={({ field: { value, ref, ...fieldProps }, fieldState }) => (
                        <FormItem className="flex flex-col flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["phone-label"]}*`} />
                          <div className="h-0 overflow-hidden">
                            <FormControl>
                              <Input
                                className="hiddenInput"
                                ref={ref}
                                tabIndex={-1}
                                autoComplete="nope"
                                aria-describedby={undefined}
                              />
                            </FormControl>
                          </div>
                          <div className="telInputCustom focus-within:ring-[2px] ring-offset-2  ring-blue-500/50 outline-none">
                            <FormControl>
                              <MuiTelInput
                                aria-describedby={undefined}
                                {...fieldProps}
                                inputProps={{ style: { padding: "0.5rem" } }}
                                defaultCountry={"US"}
                                onlyCountries={["US", "CA"]}
                                value={value ?? ""}
                                disabled={loading}
                                error={fieldState.invalid ? true : false}
                              />
                            </FormControl>
                          </div>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/**---------------------------------- company name */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="quote_company_name"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={formCopy["company-name-label"]} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["company-name-placeholder"]}
                              {...field}
                              className="font-saira"
                              disabled={loading}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/**---------------------------------- site info block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={3} />

              <div className="pb-[60px] w-full flex flex-col gap-[12px]">
                <AppText type="TITLE_MEDIUM" className="mb-4">
                  {formCopy["question-3-title"]}
                </AppText>

                {/**---------------------------------- delivery address */}
                <div className="lg:flex-row flex flex-col gap-6">
                  <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
                    <div className="flex-1">
                      <FormField
                        control={form.control}
                        name="googlePlacesBuy"
                        render={({ field }) => {
                          return (
                            <FormItem className="flex-1 space-y-0">
                              <div className="h-0 overflow-hidden">
                                <FormControl>
                                  <Input
                                    tabIndex={-1}
                                    {...field}
                                    className="hiddenInput"
                                    autoComplete="nope"
                                    aria-describedby={undefined}
                                  />
                                </FormControl>
                              </div>

                              <SiteInput
                                label={`${formCopy["address-label"]}*`}
                                setSelectedPlace={setSelectedPlaceBuy}
                                deliveryAddressBlur={deliveryAddressBlur}
                                // error={!!errors?.selectedPlace}
                                handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                                placeholder={formCopy["address-placeholder"] || ""}
                                disabled={loading}
                              />
                              <FormMessage className="font-saira text-14 font-[400]" />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </APIProvider>

                  {/**---------------------------------- date */}
                  <FormField
                    control={form.control}
                    name="deliveryDate"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormItem className="flex flex-col flex-1 space-y-0">
                          <span
                            className={cn(
                              "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                              error && "text-red-500"
                            )}
                          >{`${formCopy["delivery-date-label"]}*`}</span>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <CNButton
                                  aria-describedby={undefined}
                                  ref={field.ref}
                                  disabled={loading}
                                  variant={"outline"}
                                  className={cn(
                                    "pl-3 text-left font-normal font-saira text-16 border-black focus-visible:ring-blue-500/50",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, "PPP")
                                  ) : (
                                    <span className="text-[#64748b]">{formCopy["delivery-date-placeholder"]}</span>
                                  )}
                                  <CalendarIcon className="w-4 h-4 ml-auto opacity-50" aria-label="calendar" />
                                </CNButton>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                disabled={(date) => date < new Date() || date > new Date("2100-01-01")}
                                initialFocus
                                className="font-saira"
                              />
                            </PopoverContent>
                          </Popover>

                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      );
                    }}
                  />
                </div>

                {/** ADDRESS ADDITIONAL FIELDS */}
                <div className="lg:flex-row flex flex-col gap-6">
                  <FormField
                    control={form.control}
                    name="Site_State__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["state-label"]}*`} />
                        <FormControl>
                          <Input
                            disabled={loading}
                            placeholder={`${formCopy["state-placeholder"]}`}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="Site_Zip__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["zip-label"]}*`} />
                        <FormControl>
                          <Input
                            disabled={loading}
                            placeholder={formCopy["zip-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />
                </div>

                <div className="lg:flex-row flex flex-col gap-6">
                  <FormField
                    control={form.control}
                    name="Site_City__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["city-label"]}*`} />
                        <FormControl>
                          <Input
                            disabled={loading}
                            placeholder={formCopy["city-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="Site_Country__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["country-label"]}*`} />
                        <FormControl>
                          <Input
                            disabled={loading}
                            placeholder={formCopy["country-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            {/**---------------------------------- textarea block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={4} noBorder />

              <div className="pb-[60px] flex-1">
                <AppText type="TITLE_MEDIUM" className="mb-4">
                  {formCopy["question-4-title"]}
                </AppText>

                <AppText type="BODY_MEDIUM">{formCopy["question-4-description"]}</AppText>

                <div className="mt-3">
                  <FormField
                    control={form.control}
                    name="quote_anythingElse"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex-1 space-y-0">
                          <Textarea
                            aria-label="textarea for additional info"
                            placeholder={formCopy["question-4-placeholder"]}
                            maxLength={5000}
                            {...field}
                            className="font-saira"
                          />
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/******************* SUBMIT BUTTON ************************ */}
            <div className="bg-white flex flex-row-reverse flex-wrap justify-start px-[40px] mt-10">
              {submitting ? (
                <button
                  id="button-request-quote-buy"
                  className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-2
               transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
                  disabled
                >
                  <div
                    className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                  ></div>
                  <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                    {formCopy.submitting}
                  </AppText>
                </button>
              ) : (
                <>
                  <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

                  <AppButton
                    intent="primary"
                    label={formCopy.submit}
                    type="submit"
                    className="px-10"
                    onMouseDown={handleFormSubmit}
                  />
                </>
              )}
            </div>
          </form>
        </Form>
      </div>
    </ContentLayout>
  );
};

export default RequestQuoteToBuyForm;
