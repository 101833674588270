export const SERVICE_OPTIONS = [
  {
    key: "Service",
    formCopyKey: "service-1-title",
    title: "service-1-title",
    description: "service-1-description",
  },
  {
    key: "Invoice",
    formCopyKey: "service-1-title",
    title: "service-2-title",
    description: "service-2-description",
  },
  {
    key: "Relocation",
    formCopyKey: "service-1-title",
    title: "service-3-title",
    description: "service-3-description",
  },
  {
    key: "Lease Extension",
    formCopyKey: "service-1-title",
    title: "service-4-title",
    description: "service-4-description",
  },
  {
    key: "Return",
    formCopyKey: "service-1-title",
    title: "service-5-title",
    description: "service-5-description",
  },
  {
    key: "AP Information",
    formCopyKey: "service-1-title",
    title: "service-6-title",
    description: "service-6-description",
  },
];

export const STEPS = ["tab-1", "tab-2", "tab-3"];
