/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useRef } from "react";
import { X } from "lucide-react";

import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { IDot } from "./headers/SolutionCategoryHeader";

interface ISolutionCategoryDots {
  visibleTooltip: { idx: number; isLeft: boolean; top: number } | null;
  setVisibleTooltip: any;
  idx: number;
  dot: IDot;
  isMobile: boolean;
}

export const SolutionCategoryDots = ({
  visibleTooltip,
  setVisibleTooltip,
  idx,
  dot,
  isMobile,
}: ISolutionCategoryDots) => {
  const dotRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = () => {
    if (dotRef.current) {
      const { left, top } = dotRef.current.getBoundingClientRect();

      setVisibleTooltip({ idx, isLeft: left > 260, top });
    }
  };

  const handleDotKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleShowTooltip();
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        key={dot.id}
        ref={dotRef}
        onMouseLeave={() => setVisibleTooltip(null)}
        // onClick={() => handleDotClick()}
        onMouseOverCapture={handleShowTooltip}
        onKeyDown={handleDotKeyDown}
        style={{
          top: `${dot.top}%`,
          left: `${dot.left}%`,
        }}
        className={cn(
          `ring-1 focus-visible:ring-royal-blue ring-offset-1 ring-offset-transparent 
          ring-transparent absolute overflow-visible rounded-full outline-none`,
          isMobile ? "w-4 h-4" : "w-8 h-8"
        )}
      >
        <div
          className={cn(
            "bg-mellow z-9 animation-pulse fade-in absolute top-0 left-0 rounded-full cursor-pointer",
            isMobile ? "w-4 h-4" : "w-8 h-8"
          )}
        />

        {visibleTooltip && visibleTooltip.idx === idx && (
          <Tooltip
            isLeft={visibleTooltip.isLeft}
            dotTop={visibleTooltip.top}
            isMobile={isMobile}
            heading={dot.heading}
            handleTooltipClose={() => setVisibleTooltip(null)}
          />
        )}
      </div>
    </>
  );
};

interface ITooltip {
  heading: string;
  handleTooltipClose: () => void;
  isMobile: boolean;
  isLeft: boolean;
  dotTop: number;
}

const Tooltip = ({ heading, handleTooltipClose, isMobile, isLeft, dotTop }: ITooltip) => {
  return (
    <div
      style={{ top: isMobile ? `${Math.round(dotTop)}px` : "" }}
      className={cn(
        `cursor-default flex-col z-50
    bg-black-10 border-l-[2px] border-royal-blue p-4
    w-max md:max-w-[260px] max-w-[290px] 
    shadow-[10px_10px_34px_-17px_rgba(0,0,0,0.75)]`,
        isMobile
          ? "fixed top-[40%] left-[50%] -translate-x-1/2 -translate-y-1/2 pt-5"
          : isLeft
          ? "-top-[20px] absolute animate-in fade-in duration-500 right-[30px] -translate-x-[10px]"
          : "-top-[20px] absolute animate-in fade-in duration-500 left-[30px] translate-x-[10px]"
      )}
    >
      <div className="flex justify-between">
        {isMobile && (
          <button
            id="button-solution-category-dots"
            className="hover:bg-black-20 absolute right-1 top-1 p-[2px] transition outline-none focus-visible:outline-none ring-1 ring-transparent focus-visible:ring-royal-blue"
            onClick={(e) => {
              e.stopPropagation();
              handleTooltipClose();
            }}
          >
            <X size={16} aria-label="x icon" />
          </button>
        )}
      </div>
      <AppText type={"BODY_MEDIUM_EMPHASIS"} className="mb-2">
        {heading}
      </AppText>
    </div>
  );
};
