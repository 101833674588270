/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { APIProvider } from "@vis.gl/react-google-maps";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import { Input } from "@/components/ui/input";
import AppText from "@/components/AppText";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import IRequestServiceForm from "./RequestServiceForm";
import { AppButton } from "@/components/buttons/AppButton";
import { isMobilePhone } from "validator";
import { BorderNumber } from "@/components/BorderNumber";
import SiteInput from "./SiteInput";
import { OutputFormat, fromAddress, setDefaults } from "react-geocode";
import { GeocodingResponse } from "../request-quote/constants";
import CustomFormLabel from "@/components/CustomFormLabel";
import { CustomFormMessage } from "@/components/CustomFormMessage";

let addressInterval: any;

setDefaults({
  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!,
  language: "en",
  outputFormat: OutputFormat.JSON,
});

const generalInformationSchema = z.object({
  Portal_First_Name__c: z.string().min(1, {
    // SuppliedName new name
    message: "firstNameRequired",
  }),
  Portal_Last_Name__c: z.string().min(1, {
    // add to SuppliedName
    message: "lastNameRequired",
  }),
  Portal_Email_Address__c: z.string().email({
    message: "invalidEmail",
  }),
  Portal_Phone_Number__c: z.string().refine(isMobilePhone, {
    message: "invalidPhone",
  }),
  SuppliedCompany: z.string(),
  Alternate_Contact__c: z.string().optional(),
  Alternate_Contact_Email__c: z.string().optional(),
  Alternate_Contact_Phone__c: z
    .string()
    .refine(isMobilePhone, {
      message: "invalidPhone",
    })
    .optional()
    .or(z.literal("")),

  googlePlaces: z.string().min(1, { message: "Required" }),
  Requested_Country__c: z.string().min(1, {
    message: "countryRequired",
  }),
  Requested_State__c: z.string().min(1, {
    message: "stateRequired",
  }),
  Requested_Zip__c: z.string().min(1, {
    message: "zipcodeRequired",
  }),
  Requested_City__c: z.string().min(1, {
    message: "cityRequired",
  }),
  Requested_Address1__c: z.string().min(1, {
    message: "addressRequired",
  }),
});

export type GeneralInformationSchema = z.infer<typeof generalInformationSchema>;

const GeneralInfoForm: React.FC<IRequestServiceForm> = ({ onPrevHandler, onSubmitSuccess, formCopy }) => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: 1 } | Record<string, never>>({});
  const errorMessageShown = useRef(false);

  const form = useForm<z.infer<typeof generalInformationSchema>>({
    resolver: zodResolver(generalInformationSchema),
    defaultValues: {
      // Default values for non required fields
      Portal_First_Name__c: "",
      Portal_Last_Name__c: "",
      Portal_Email_Address__c: "",
      Portal_Phone_Number__c: "",
      SuppliedCompany: "",
      Alternate_Contact__c: "",
      Alternate_Contact_Email__c: "",
      Alternate_Contact_Phone__c: "",
      Requested_Country__c: "",
      Requested_State__c: "",
      Requested_Zip__c: "",
      Requested_City__c: "",
      Requested_Address1__c: "",
      googlePlaces: "",
    },
    // values: formData as GeneralInformationSchema,
    mode: "onBlur",
  });

  const oldFieldsValues: any = useRef(form.getValues());
  const watchAllFields: any = form.watch();
  let target: any = "";

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    for (target of Object.keys(watchAllFields)) {
      if (watchAllFields?.[target] !== oldFieldsValues?.current?.[target]) {
        form.trigger(target);
      }
    }

    oldFieldsValues.current = watchAllFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const data = (await fromAddress(selectedPlace?.formatted_address)) as GeocodingResponse;

      let city = "",
        state = "",
        zipCode = "",
        address = "",
        streetNumber = "",
        country = "";
      const components = data.results[0].address_components || [];

      for (const target of components) {
        const types = target.types || [];

        if (types.includes("locality")) {
          city = target.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = target.long_name;
          const stateData = JSON.stringify({ long: target.long_name, short: target.short_name });
          localStorage.setItem("siteState", stateData);
        } else if (types.includes("postal_code")) {
          zipCode = target.long_name;
        } else if (types.includes("route")) {
          //(types.includes("route") || types.includes("neighborhood"))

          address = target.long_name;
        } else if (types.includes("street_number")) {
          streetNumber = target.long_name;
        } else if (types.includes("country")) {
          country = target.long_name;
        }
      }

      form.setValue("Requested_Country__c", country);
      form.setValue("Requested_State__c", state);
      form.setValue("Requested_Zip__c", zipCode);
      form.setValue("Requested_City__c", city);
      form.setValue("Requested_Address1__c", `${streetNumber} ${address}`);

      setTimeout(() => {
        form.trigger("Requested_Country__c");
        form.trigger("Requested_State__c");
        form.trigger("Requested_Zip__c");
        form.trigger("Requested_City__c");
      }, 500);
    }
  };

  useEffect(() => {
    clearInterval(addressInterval);
    if (selectedPlace) {
      const tmp = { ...errors };
      delete tmp.selectedPlace;
      setErrors(tmp);
      getSiteData();
      form.setValue("googlePlaces", "valid");
    } else {
      form.setValue("googlePlaces", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const onSubmit = (values: z.infer<typeof generalInformationSchema>) => {
    onSubmitSuccess(values);
  };

  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  const siteAddressBlur = () => {
    if (!selectedPlace) {
      setErrors((prev) => ({ ...prev, selectedPlace: 1 }));
    }

    addressInterval = setTimeout(() => {
      checkAfterBlur();
    }, 500);
  };

  const checkAfterBlur = () => {
    if (!selectedPlace) {
      if (!errorMessageShown.current) {
        toast.error("Please select place from suggestions list");
        errorMessageShown.current = true;
      }
      form.setValue("Requested_Country__c", "");
      form.setValue("Requested_State__c", "");
      form.setValue("Requested_Zip__c", "");
      form.setValue("Requested_City__c", "");
      form.setValue("Requested_Address1__c", "");
    }
  };

  const handleDeliveryAddressOnChange = () => {
    setSelectedPlace(null);
    errorMessageShown.current = false;
  };

  return (
    <Form {...form}>
      <form
        className="lg:col-span-10 lg:col-start-2 lg:px-10 pb-[56px] col-span-4 px-6 bg-white relative"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex gap-[18px]">
          <BorderNumber number={1} />
          <div className="pb-14 lg:pr-10 flex flex-col flex-1 gap-6">
            <AppText type="TITLE_MEDIUM">{formCopy["page-2-contact-details-title"]}</AppText>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Portal_First_Name__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["first-name-label"]}*`} />
                    <FormControl>
                      <Input placeholder={formCopy["first-name-placeholder"]} {...field} aria-describedby={undefined} />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="Portal_Last_Name__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["last-name-label"]}*`} />
                    <FormControl>
                      <Input placeholder={formCopy["last-name-placeholder"]} {...field} aria-describedby={undefined} />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Portal_Email_Address__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["email-label"]}*`} />
                    <FormControl>
                      <Input placeholder={formCopy["email-placeholder"]} {...field} aria-describedby={undefined} />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="Portal_Phone_Number__c"
                rules={{
                  validate: (value) =>
                    matchIsValidTel(value ?? "", {
                      onlyCountries: ["US", "CA"],
                    }),
                }}
                render={({ field: { value, ref, ...fieldProps }, fieldState: { error, invalid } }) => (
                  <FormItem className=" flex flex-col flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["phone-label"]}*`} />
                    <div className="h-0 overflow-hidden">
                      <FormControl>
                        <Input
                          className="hiddenInput"
                          ref={ref}
                          tabIndex={-1}
                          autoComplete="nope"
                          aria-describedby={undefined}
                        />
                      </FormControl>
                    </div>
                    <div className="telInputCustom focus-within:ring-[2px] ring-offset-2  ring-blue-500/50 outline-none">
                      <FormControl>
                        <MuiTelInput
                          {...fieldProps}
                          aria-describedby={undefined}
                          inputProps={{ style: { padding: "0.5rem" } }}
                          defaultCountry={"US"}
                          onlyCountries={["US", "CA"]}
                          value={value ?? ""}
                          error={invalid ? true : false}
                        />
                      </FormControl>
                    </div>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="SuppliedCompany"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={formCopy["company-name-label"]} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["company-name-placeholder"]}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-[18px]">
          <BorderNumber number={2} />
          <div className="pb-14 lg:pr-10 flex flex-col flex-1 gap-6">
            <AppText type="TITLE_MEDIUM">{formCopy["page-2-alternate-contact-details-title"]}</AppText>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Alternate_Contact__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["alternate-name-label"]}`} />
                    <FormControl>
                      <Input
                        placeholder={`${formCopy["alternate-name-placeholder"]}`}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Alternate_Contact_Email__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["alternate-email-label"]} `} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["alternate-email-placeholder"]}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="Alternate_Contact_Phone__c"
                rules={{
                  validate: (value) =>
                    matchIsValidTel(value ?? "", {
                      onlyCountries: ["US", "CA"],
                    }),
                }}
                render={({ field: { value, ...fieldProps }, fieldState: { error, invalid } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["alternate-phone-label"]} `} />
                    <div className="telInputCustom focus-within:ring-[2px] ring-offset-2  ring-blue-500/50 outline-none">
                      <FormControl>
                        <MuiTelInput
                          {...fieldProps}
                          aria-describedby={undefined}
                          inputProps={{ style: { padding: "0.5rem" } }}
                          defaultCountry={"US"}
                          onlyCountries={["US", "CA"]}
                          value={value ?? ""}
                          error={invalid ? true : false}
                        />
                      </FormControl>
                    </div>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-[18px]">
          <BorderNumber number={3} noBorder />

          <div className="pb-14 lg:pr-10 flex flex-col flex-1 gap-6">
            <AppText type="TITLE_MEDIUM">{formCopy["page-2-site-information-title"]}</AppText>

            <div className="lg:flex-row flex flex-col gap-6">
              <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name="googlePlaces"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormItem className="flex-1 space-y-0">
                          <div className="h-0 overflow-hidden">
                            <FormControl>
                              <Input
                                {...field}
                                className="hiddenInput"
                                tabIndex={-1}
                                autoComplete="nope"
                                role="presentation"
                                aria-describedby={undefined}
                              />
                            </FormControl>
                          </div>

                          <SiteInput
                            label={`${formCopy["address-label"]}*`}
                            setSelectedPlace={setSelectedPlace}
                            deliveryAddressBlur={siteAddressBlur}
                            error={!!errors?.selectedPlace}
                            handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                            placeholder={formCopy["address-placeholder"]}
                          />
                          {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </APIProvider>

              <div className="flex-1"></div>
            </div>

            {/** state and zip code */}
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Requested_State__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["state-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={`${formCopy["state-placeholder"]}`}
                        {...field}
                        autoComplete={"off"}
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="Requested_Zip__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["zip-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["zip-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>

            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Requested_City__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["city-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["city-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="Requested_Country__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["country-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["country-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className=" lg:pr-10 relative flex flex-wrap justify-between w-full pl-0 sm:pl-[52px] pr-0">
          <AppButton
            intent="secondary"
            label={formCopy["previous-cta"]}
            className="px-10"
            onMouseDown={onPrevHandler}
            onClick={onPrevHandler}
          />
          <button id="targetSubmit" className="hidden" type="submit" aria-label="submit target"></button>
          <AppButton intent="primary" className="px-10" label={formCopy["next-cta"]} onMouseDown={handleFormSubmit} />
        </div>
      </form>
    </Form>
  );
};

export default GeneralInfoForm;
