/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomFormLabel from "@/components/CustomFormLabel";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import React, { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";

const SiteInputRAQB = ({
  label,
  setSelectedPlace,
  deliveryAddressBlur,
  handleDeliveryAddressOnChange,
  placeholder,
  disabled,
  error,
  className,
}: {
  label: string;
  placeholder: string;
  disabled?: boolean;
  setSelectedPlace: React.Dispatch<React.SetStateAction<any>>;
  deliveryAddressBlur: (e: FocusEvent<HTMLInputElement>) => void;
  handleDeliveryAddressOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  className?: string;
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["name", "formatted_address"], // "geometry"
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      setSelectedPlace(placeAutocomplete.getPlace());
    });
  }, [setSelectedPlace, placeAutocomplete]);

  return (
    <div className={cn("flex flex-col flex-1", className)}>
      <CustomFormLabel label={label} />
      <Input
        type="text"
        ref={inputRef}
        placeholder={placeholder}
        onChange={handleDeliveryAddressOnChange}
        onBlur={deliveryAddressBlur}
        className={cn("font-saira font-[400] text-16", error && "border-red-500")}
        disabled={disabled}
        autoComplete={"off"}
        aria-label="enter location"
        aria-autocomplete="none"
        list="autocompleteOff"
      />
    </div>
  );
};

export default SiteInputRAQB;
