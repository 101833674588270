/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";

import getDataFromCookie from "@/components/utmData/utmHelper";

// ! todo: make env variables read from server
const OwnerId = "3D00G8c000006wyqj";
const RequesterC = "0058c00000CpVJOAA3";

export const preparePayload = async (values: any, unit: any, siteAddress: string) => {
  const temporaryData: { [key: string]: string }[] = [
    {
      Key: "",
      Name: "Delivery Date",
      Value: format(values.deliveryDate, "MM/dd/yyyy"),
    },
    {
      Key: "",
      Name: "Duration",
      Value: "I'd like to purchase a unit instead of rent",
    },
    {
      Key: "",
      Name: "Product Unit Number",
      Value: unit.unitNumber,
    },
    {
      Key: "",
      Name: "Product Branch",
      Value: unit.branchName,
    },
    {
      Key: "",
      Name: "Product Name",
      Value: unit.name,
    },
    {
      Key: "",
      Name: "Product Price",
      Value: unit.price,
    },
    {
      Key: "",
      Name: "Product Location",
      Value: unit.unitLocation,
    },
    {
      Key: "",
      Name: "Interests",
      Value: values.quote_anythingElse,
    },
  ];

  const payload = {
    Purchase_Decision__c: "Purchase",
    Purchase__c: true,
    // LeadQA_Lease_Term__c: "I'd like to purchase a unit instead of rent",

    "Quote Type": "Purchase",
    Type: 100,
    Token: "ed548cf1-3106-461b-ba68-fa98b3f5bd0a",
    Campaign: "Online Showroom",
    // Campaign: "WillScot Website",
    IsDisplayAd: false,
    Quote_Type__c: "Sale - Used",
    FirstName: values.FirstName,
    LastName: values.LastName,
    Company: values.quote_company_name,
    Email: values.Email,
    Phone: values.Phone,
    // Email_Opt_In__c: !values.receiveUpdates,
    SiteAddress1: "N/A",
    // Address1: "N/A",
    // City: "N/A",
    // State: "N/A",
    // Zip: "N/A",
    // Country: "N/A",
    Site_Address__c: "N/A",
    Site_City__c: "N/A",
    Site_State__c: "N/A",
    Site_Zip__c: "N/A",
    Fax: "",
    SendDebugEmail: false,
    DebugSource: null,
    Requester__c: RequesterC,
    OwnerId,
    SiteZip: "N/A",
    Data: temporaryData.filter((item: any) => !!item.Value),
    LeadQA_Additional_Information__c: values.quote_anythingElse,
    Interest: values.quote_anythingElse,
    LeadQA_Requested_Delivery_Date__c: "",
    // Comments: "These are the comments",
    // Comments__c: values.anythingElse, // textarea mapped to LeadQA_Additional_Information__c
    // Purchase__c: recommendedUnitsSwitch,
    // LeadQA_Requested_Delivery_Date__c: values.deliveryDate,
    // Delivery_City__c: "N/A",
    // Delivery_Postal_Code__c: "N/A",
    // Delivery_State__c: "N/A",
    // Delivery_Street__c: "N/A",
    // Comments__c: values.anythingElse, // textarea mapped to LeadQA_Additional_Information__c
    // Purchase__c: recommendedUnitsSwitch,
    // QuoteId: "123ABC",
    // Category: "TRADITIONAL_PRODUCT",
    // JobTitle: "",
    ga_campaign: "",
    ga_content: "",
    ga_medium: "",
    ga_source: "",
    ga_term: "",
  };

  payload.SiteAddress1 = siteAddress || "N/A";
  payload.Site_Address__c = siteAddress || "N/A";
  payload.Site_City__c = values.Site_City__c || "N/A";
  payload.Site_State__c = values.Site_State__c || "N/A";
  payload.Site_Zip__c = values.Site_Zip__c || "N/A";
  payload.SiteZip = values.Site_Zip__c || "N/A";

  // Send UTM Data
  const utmDataFromCookie = getDataFromCookie();

  if (utmDataFromCookie.utm_campaign) {
    payload.ga_campaign = utmDataFromCookie.utm_campaign;
  }
  if (utmDataFromCookie.utm_content) {
    payload.ga_content = utmDataFromCookie.utm_content;
  }
  if (utmDataFromCookie.utm_medium) {
    payload.ga_medium = utmDataFromCookie.utm_medium;
  }
  if (utmDataFromCookie.utm_source) {
    payload.ga_source = utmDataFromCookie.utm_source;
  }
  if (utmDataFromCookie.utm_term) {
    payload.ga_term = utmDataFromCookie.utm_term;
  }

  return payload;
};

export const parseData = (unit: any, Entry: any) => {
  const tmp: any = {
    name: unit.fields?.name,
    obo: unit.fields?.obo,
    price: unit.fields?.price,
    unitNumber: unit.fields?.unitNumber,
    productType: unit.fields?.productType,
  };

  const branchId = unit.fields?.branch?.sys?.id;

  if (branchId) {
    for (const target of Entry || []) {
      const subId = target.sys?.id;

      if (subId === branchId) {
        tmp.unitLocation = `${target.fields?.city}, ${target.fields?.state}`;
        tmp.branchName = target.fields?.name;

        break;
      }
    }
  }

  return tmp;
};
