/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import AppText from "@/components/AppText";
import { ChatBlue } from "@/assets/Icons";
import { AppIconButton } from "@/components/buttons/AppIconButton";
import ContentLayout from "@/components/layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";
import { AppButton } from "./buttons/AppButton";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";

interface IAccordionSection {
  id: string;
  title: string;
  content: any;
}
interface IFaqAccordionWithText {
  id: string;
  label: string;
  heading: string;
  content: any;
  cardHeading?: string;
  cardContent?: string;
  cardCtaLabel: string;
  cardCtaLink: string;
  sections: IAccordionSection[];
  paddingBottom?: string;
}

const FAQsWithCard = ({
  id,
  label,
  heading,
  content,
  cardHeading,
  cardContent,
  cardCtaLabel,
  cardCtaLink,
  sections,
  paddingBottom,
}: IFaqAccordionWithText) => {
  const questionBlock = () => {
    const isSupposedToOpenChatInsteadOfLink =
      cardCtaLabel === "Start a chat" || cardCtaLink === "#open-integrated-chat-box";
    const cardCtaCustomLink = isSupposedToOpenChatInsteadOfLink ? undefined : cardCtaLink;
    const onClickHandler = isSupposedToOpenChatInsteadOfLink
      ? (e: any) => {
          e.preventDefault();
          console.log(`Opening: Chat`);
          const button = document.querySelector(".helpButtonEnabled") as HTMLButtonElement;
          button?.click();
        }
      : undefined;

    return (
      <>
        <ChatBlue />

        {cardHeading && (
          <AppText type={"TITLE_MEDIUM"} className="mt-4 mb-4 uppercase" as="h4">
            {cardHeading}
          </AppText>
        )}

        {cardContent && (
          <AppText type={"BODY_MEDIUM"} className="mb-6">
            {cardContent}
          </AppText>
        )}

        <AppIconButton
          link={cardCtaCustomLink}
          onClick={onClickHandler}
          intent={"secondary"}
          icon={<ChevronRight size={20} aria-label="chevron right icon" />}
          label={cardCtaLabel}
          iconOnRightSide={true}
          className={"max-lg:mt-[24px] max-lg:self-start"}
        />
      </>
    );
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id} paddingBottom={paddingBottom}>
      <div className="lg:flex-row flex flex-col gap-6">
        <div className="flex-1">
          {label && (
            <AppText type={"LABEL_LARGE"} className="text-royal-blue mb-2">
              {label}
            </AppText>
          )}

          {heading && (
            <AppText type={"HEADLINE_MEDIUM"} className="mb-4" as="h2">
              {heading}
            </AppText>
          )}

          {content && (
            <AppText type={"BODY_LARGE_EMPHASIS"} as="h3">
              {content}
            </AppText>
          )}

          {cardContent ? (
            <div className="bg-navy-blue-10 lg:flex flex-col hidden px-8 py-6 mt-6">{questionBlock()}</div>
          ) : (
            <AppButton
              link={cardCtaLink}
              intent={"secondary"}
              title={cardCtaLabel}
              label={cardCtaLabel}
              className="mt-6"
            />
          )}
        </div>

        <Accordion type="single" collapsible className=" flex flex-col flex-1 w-full gap-4">
          {sections.map((section: any) => (
            <AccordionItem value={section.id} key={section.id}>
              <AccordionTrigger
                size={24}
                className={`underline-none [&>span]:text-pretty [&>span]:whitespace-break-spaces 
                  [&>span]:text-left w-full hover:no-underline flex text-16 font-[500] gap-10 
                  font-saira tracking-[-0.1px]`}
              >
                <AppText type={"BODY_MEDIUM_EMPHASIS"} as="h4">
                  {section.title}
                </AppText>
                {/* {section.title} */}
              </AccordionTrigger>

              <AccordionContent className="px-8 py-1">
                <RichTextRenderer
                  richTextDocument={section.content}
                  id={section.id}
                  locations
                  className={cn("text-sm")}
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </ContentLayout>
  );
};

export default React.memo(FAQsWithCard);
