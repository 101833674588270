/* eslint-disable @next/next/no-img-element */
"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import { AppButton } from "../buttons/AppButton";
import CustomLink from "../CustomLink";
import Carousel from "../Carousel";
import { useMediaQuery } from "@/hooks/mediaQuery";

interface ISaleCard {
  id: string;
  heading: string;
  imgSrc: string;
  imgAlt: string;
  ctaLabel: string;
  cardLink: string;
}

interface ISalesShowroomPopularSearch {
  label: string;
  heading: string;
  ctaLabel: string;
  ctaLink: string;
  cards: ISaleCard[];
  paddingBottom?: string;
}

const SalesShowroomPopularSearch = ({
  label,
  heading,
  ctaLabel,
  ctaLink,
  cards,
  paddingBottom,
}: ISalesShowroomPopularSearch) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <>
      <ContentLayout
        bgColorClass="bg-royal-blue"
        innerClass="sm:pt-[48px] pt-[48px] pb-0 sm:pb-0"
        paddingBottom={paddingBottom}
      >
        <div className="flex flex-col items-center mb-10">
          {label && (
            <AppText type="LABEL_LARGE" className="text-mellow mb-2 text-center">
              {label}
            </AppText>
          )}
          <AppText type="HEADLINE_LARGE" as="h2" className="mb-4 text-center text-white">
            {heading}
          </AppText>
          <AppButton label={ctaLabel} link={ctaLink} intent={"primary"} bg={"dark"} />
        </div>
      </ContentLayout>

      <ContentLayout bgColorClass="bg-royal-blue" innerClass="sm:pb-[80px] pb-[48px] pt-0 sm:pt-0 pl-4 pr-0 lg:px-8">
        <div>
          <Carousel
            wrapperClassName={"flex"}
            className={"flex-1 flex h-full items-stretch"}
            slidesPerView={1}
            spaceBetween={10}
            paginationType={"pageSelect"}
            bg={"dark"}
            cardsOverflow={isDesktop ? false : true}
            breakpoints={{
              0: {
                slidesPerView: 1.3,
                spaceBetween: 4,
                slidesPerGroup: 1,
              },
              640: {
                slidesPerView: 2.3,
                spaceBetween: 4,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 12,
                slidesPerGroup: 1,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 28,
                slidesPerGroup: 1,
              },
            }}
          >
            {cards.map((card: ISaleCard) => (
              <SwiperSlide key={card.id} className="p-[6px]">
                <ShowCard key={card.id} card={card} />
              </SwiperSlide>
            ))}
          </Carousel>
        </div>
      </ContentLayout>
    </>
  );
};

export default SalesShowroomPopularSearch;

const ShowCard = ({ card }: { card: ISaleCard }) => (
  <CustomLink
    href={card.cardLink}
    className="col-span-3 group bg-white h-full flex-1 px-[20px] pt-8 pb-4 flex flex-col gap-2 items-center"
  >
    <AppText type="TITLE_SMALL" className="mb-4 text-center">
      {card.heading}
    </AppText>

    <div className="overflow-hidden">
      <img
        src={card.imgSrc}
        alt={card.imgAlt}
        // sizes="100vw"
        // width={0}
        // height={0}
        className="group-hover:scale-105 w-full transition"
      />
    </div>

    <AppButton label={card.ctaLabel} intent={"ghost"} className="mt-auto" />
  </CustomLink>
);
