/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";

import ContentLayout from "./layouts/ContentLayout";

import Image from "next/image";
import AppText from "./AppText";
import { AppButton } from "./buttons/AppButton";
import { cn } from "@/lib/utils";
import { AppIconButton } from "./buttons/AppIconButton";

import { BUTTON_MEDIUM } from "@/styles";
import RichTextRenderer from "./RichTextRenderer";
import { colorsMap, fallbackColors } from "./constants";
import { ChevronRight } from "lucide-react";
import { NO_PREVIEW_PLACEHOLDER } from "@/contentful/constants";

interface IStep {
  id: string;
  image: string;
  imgAlt: string;
  placeholder: string;
  tab: string;
  heading: string;
  content: any;
  ctaLabel: string;
  ctaLink: string;
}

interface IAccordionSteps {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  steps: IStep[];
  background: string;
  paddingBottom?: string;
}

const AccordionSteps = ({ label, heading, subheading, steps, id, background, paddingBottom }: IAccordionSteps) => {
  const [tabSelected, setTabSelected] = useState(steps[0]);

  const { labelColor, textColor, bgColorClass, bg, border } = colorsMap[background] ?? fallbackColors;

  const handleTabClick = (id: string) => {
    if (tabSelected.id === id) return;
    const newTab = steps.find((step) => step.id === id);

    newTab && setTabSelected(newTab);
  };

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      <div className="md:flex-row flex flex-col-reverse gap-10">
        <div className="flex flex-col justify-center flex-1 gap-6">
          {label && (
            <AppText type="LABEL_LARGE" className={labelColor}>
              {label}
            </AppText>
          )}

          <AppText type="HEADLINE_MEDIUM" className={textColor}>
            {heading}
          </AppText>
          <AppText type="SUB_HEADING_SMALL" className={textColor}>
            {subheading}
          </AppText>
          <div className="sm:flex-row sm:gap-10 flex flex-col gap-8">
            <ul
              className={cn(
                "gap-x-2 md:gap-x-6 sm:border-r-2 border-navy-blue-20 flex flex-col justify-between w-auto border-r-0"
              )}
            >
              {steps.map((step) => (
                <li
                  key={step.id}
                  className={cn(
                    "sm:border-r-2 sm:border-l-0 sm:-mr-[2px] border-l-2 border-r-0 -ml-[2px] pl-4 sm:pl-0 pr-4",
                    step.id === tabSelected.id ? border : "border-navy-blue-20"
                  )}
                >
                  <AppButton onClick={() => handleTabClick(step.id)} label={step.tab} intent="ghost" bg={bg} />
                </li>
              ))}
            </ul>

            <div>
              <AppText type="TITLE_LARGE" className={cn("line-clamp-1 mb-2", textColor)}>
                {tabSelected.heading}
              </AppText>

              <RichTextRenderer
                richTextDocument={tabSelected.content}
                id={`rt-${id}`}
                locations
                className={textColor}
              />

              <AppIconButton
                intent="ghost"
                iconOnRightSide
                icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                label={tabSelected.ctaLabel}
                link={tabSelected.ctaLink}
                className={`${BUTTON_MEDIUM} mt-8`}
                bg={bg}
              />
            </div>

            <div></div>
          </div>
        </div>

        <div className="relative flex flex-1 w-full aspect-[16/9]">
          <Image
            src={tabSelected.image}
            alt={tabSelected.imgAlt}
            className="object-cover w-full aspect-[16/9]"
            fill
            sizes="(max-width: 1024px) 100vw, 758px"
            placeholder="blur"
            blurDataURL={tabSelected.placeholder || NO_PREVIEW_PLACEHOLDER}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default AccordionSteps;
