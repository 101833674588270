/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import ContentLayout from "@/components/layouts/ContentLayout";
import FormTabRequestQuoteBuy from "./FormTabRequestQuoteBuy";
import { IDict } from "@/components/constants";
import FormTabRequestQuote from "./FormTabRequestQuote";
import FormRequestSupport from "./FormRequestSupport";
import { useEffect } from "react";
import { useFormData } from "@/store/servicesFormsStore";
import ReCaptchaProvider from "../recaptcha/ReCaptchaProvider";

const DynamicForm = ({ formModel, dict, type }: { formModel: any; dict: IDict; type: string }) => {
  const resetForm = useFormData((state) => state.resetForm);

  useEffect(() => {
    return () => resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentLayout
      bgColorClass="bg-black-5"
      innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px] "
    >
      {type === "request-quote" && formModel && formModel[0] && (
        <ReCaptchaProvider>
          <FormTabRequestQuote key={formModel[0].tabLabel} tab={formModel[0]} dict={dict} />
        </ReCaptchaProvider>
      )}
      {type === "request-quote-buy" && formModel && formModel[0] && (
        <ReCaptchaProvider>
          <FormTabRequestQuoteBuy key={formModel[0].tabLabel} tab={formModel[0]} dict={dict} />
        </ReCaptchaProvider>
      )}
      {type === "request-service" && formModel && (
        <ReCaptchaProvider>
          <FormRequestSupport formModel={formModel} />
        </ReCaptchaProvider>
      )}
    </ContentLayout>
  );
};

export default DynamicForm;
