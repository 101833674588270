import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../constants";
import AppText from "../AppText";

type Props = {
  id: string;
  formEmbed: string;
  background: string;
  heading: string;
  subheading: string;
  className?: string;
};

const MiniForm = ({ background, heading, subheading, className, formEmbed }: Props) => {
  const { bgColorClass, textColor } = colorsMap[background] || fallbackColors;

  return (
    <div className={cn("flex justify-center items-center max-w-2xl w-full flex-col pt-8", bgColorClass, className)}>
      <AppText type="HEADLINE_SMALL" className={cn(textColor, "text-center text-pretty")}>
        {heading}
      </AppText>
      <AppText type="BODY_MEDIUM" className={cn(textColor, "text-center text-pretty")}>
        {subheading}
      </AppText>

      <div className="flex flex-col w-full" dangerouslySetInnerHTML={{ __html: formEmbed }}></div>
    </div>
  );
};

export default MiniForm;
