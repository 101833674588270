/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Form } from "@/components/ui/form";
import FormGroupWrapper from "./FormGroupWrapper";

import AppButton from "@/components/buttons/AppButton";
import { getGeocodingAddress } from "@/components/form/helpers";
import { FieldCalendar, FieldDropdown, FieldGoogleAddress, FieldPhone, FieldText, FieldTextArea } from "./FormElements";
import { createDynamicSchemaSupportThirdTab } from "./schemaHelper";
import { useFormData } from "@/store/servicesFormsStore";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";

const FormRequestSupportStep2 = ({ formData }: { formData: any }) => {
  const formSchema = z.object({});

  const setStepAndFormData = useFormData((state) => state.setStepAndFormData);
  const setFormData = useFormData((state) => state.setFormData);
  const formDataStore = useFormData((state) => state.formData);

  const [selectedPlace, setSelectedPlaceBuy] = useState<google.maps.places.PlaceResult | null>(null);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);

  const googlePlacesTouched = useRef(false);

  const formDefaultValues = useMemo(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const res: any = {};

    (formData.groups || []).forEach((group: any) => {
      (group.rows || []).forEach((row: any) => {
        (row.fields || []).forEach((field: any) => {
          res[field.name] = formDataStore[field.name] || "";
        });
      });
    });

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onBlur",
  });

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const geoRes = await getGeocodingAddress(selectedPlace?.formatted_address, true); // second argument is for saving country to local storage

      form.setValue("Requested_country", geoRes.country);
      form.setValue("Requested_state", geoRes.state);
      form.setValue("Requested_zip", geoRes.zipCode);
      form.setValue("Requested_city", geoRes.city);

      let siteAddress = "";

      if (geoRes.address) {
        if (geoRes.streetNumber) {
          siteAddress = `${geoRes.streetNumber} ${geoRes.address}`;
        } else {
          siteAddress = `${geoRes.address}`;
        }
      } else if (geoRes.formatted_address) {
        siteAddress = geoRes.formatted_address;
      } else {
        siteAddress = geoRes.neighborhood;
      }

      setFormData({ Site_Street_Web__c: siteAddress });

      setTimeout(() => {
        form.trigger("Requested_country");
        form.trigger("Requested_state");
        form.trigger("Requested_zip");
        form.trigger("Requested_city");
      }, 500);
    }
  };

  useEffect(() => {
    if (selectedPlace && selectedPlace?.formatted_address) {
      getSiteData();
      form.setValue("Requested_address", "valid", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      if (googlePlacesTouched.current) {
        form.setValue("Requested_address", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaSupportThirdTab(formData.groups);

      const formSchema = z.object(zObj);

      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [formData]);

  const deliveryAddressBlur = () => {
    googlePlacesTouched.current = true;
  };

  const handleDeliveryAddressOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setSelectedPlaceBuy(null);
    }
  };

  async function onSubmit(values: z.infer<typeof dynamicFormSchema>) {
    setStepAndFormData(3, values);
  }

  const handleNextButton = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  const handleBackButton = () => {
    setStepAndFormData(1, form.getValues());
  };

  return (
    <div className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-6 pt-12 bg-white">
      <Form {...form}>
        <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
          {(formData.groups || []).map((group: any, index: number) => (
            <FormGroupWrapper
              key={group.id}
              groupHeading={group.heading}
              borderNumber={index + 1}
              groupDescription={group.description}
              noBorder={index === formData.groups.length - 1}
              noBottomPadding={group.groupType === "fieldGroupOptionCards"}
            >
              {(group.rows || []).map((row: any) => (
                <div key={row.id} className="lg:flex-row flex flex-col gap-6">
                  {row.fields?.length &&
                    row.fields.map((field: any) => {
                      if (field.fieldType === "fieldText") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldPhone") {
                        return (
                          <FieldPhone
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            placeholder={field.placeholder || "Enter your phone number..."}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldCalendar") {
                        return (
                          <FieldCalendar
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      } else if (field.fieldType === "fieldGoogleAddress") {
                        return (
                          <FieldGoogleAddress
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                            deliveryAddressBlur={deliveryAddressBlur}
                            setSelectedPlaceBuy={setSelectedPlaceBuy}
                            handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                          />
                        );
                      } else if (field.fieldType === "fieldTextArea") {
                        return (
                          <FieldTextArea
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            loading={false}
                            placeholder={field.placeholder}
                          />
                        );
                      } else if (field.fieldType === "fieldDropdown") {
                        return (
                          <FieldDropdown
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            label={field.label}
                            options={field.options}
                          />
                        );
                      } else if (field.fieldType === "fieldEmail") {
                        return (
                          <FieldText
                            key={field.id}
                            control={form.control}
                            name={field.name}
                            placeholder={field.placeholder}
                            loading={false}
                            label={field.label}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              ))}
            </FormGroupWrapper>
          ))}

          {/******************* SUBMIT BUTTON ************************ */}
          {/* <div className="bg-white flex flex-row-reverse flex-wrap justify-start px-[10] mt-10">
            <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

            <AppButton
              intent="primary"
              label={formData.ctaNext}
              type="submit"
              className="px-10"
              onMouseDown={handleNextButton}
            />
            <AppButton
              intent="secondary"
              label={formData.ctaBack}
              type="button"
              className="px-10"
              onMouseDown={handleBackButton}
            />
          </div> */}

          <div className="mt-10">
            <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

            <div className="flex flex-row flex-wrap justify-between gap-4 bg-white">
              <AppButton
                intent="secondary"
                label={formData.ctaBack}
                type="button"
                className="px-10"
                onMouseDown={handleBackButton}
              />

              <AppButton
                intent="primary"
                label={formData.ctaNext}
                type="submit"
                className="px-10"
                onMouseDown={handleNextButton}
              />
            </div>
          </div>
        </form>
      </Form>
      <FormFooterRecaptchaPolicy />
    </div>
  );
};

export default FormRequestSupportStep2;
