import React from "react";
import AppText from "@/components/AppText";
import { AppIconButton } from "@/components/buttons/AppIconButton";

import Image from "next/image";
import { ChevronRight } from "lucide-react";

interface PackageCardProps {
  title: string;
  subheading: string;
  buttonLink: string;
  buttonLabel: string;
  iconUrl: string;
}

const PackageCard: React.FC<PackageCardProps> = ({ title, subheading, buttonLink, iconUrl, buttonLabel }) => {
  return (
    <div className="flex flex-col bg-white max-h-[330px] px-[24px] py-[32px]">
      <div
        className={"w-[44px] h-[44px] rounded-full bg-royal-blue !fill-white flex flex-col justify-center items-center"}
      >
        <Image alt="icon" src={iconUrl} width={32} height={32} className="text-white" />
      </div>
      <AppText className="mt-[16px]" type="TITLE_LARGE">
        {title}
      </AppText>
      <AppText className="mt-[16px]" type="BODY_MEDIUM">
        {subheading}
      </AppText>

      <AppIconButton
        className={"right-2 mt-[16px]"}
        intent="ghost"
        link={buttonLink}
        label={buttonLabel}
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        iconOnRightSide
      />
    </div>
  );
};

export default React.memo(PackageCard);
