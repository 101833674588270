"use client";
import React, { useState } from "react";
import { cn } from "@/lib/utils";
import AppText from "@/components/AppText";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

interface DropdownProps {
  items: { label: string; id: string }[];
  selectedItem: { label: string; id: string };
  onItemClick: (item: string) => void;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ onItemClick, items, selectedItem, className }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={cn("w-full flex-col flex", isDropdownOpen && "shadow-md", className)}>
      <div
        className={
          "flex justify-between bg-black-10 border-b-black-20 border-b-[2px] px-[16px] h-[52px] items-center cursor-pointer"
        }
        onClick={() => setIsDropdownOpen((prevState) => !prevState)}
      >
        <AppText type={"BODY_MEDIUM"}>{selectedItem.label}</AppText>

        {isDropdownOpen ? (
          <ChevronUpIcon aria-label="chevron up icon" />
        ) : (
          <ChevronDownIcon aria-label="chevron down icon" />
        )}
      </div>

      {isDropdownOpen &&
        items
          .filter((t) => t.id !== selectedItem.id)
          .map((t) => (
            <div
              key={t.id}
              onClick={() => {
                onItemClick(t.id);
                setIsDropdownOpen(false);
              }}
              className={"cursor-pointer h-[52px] px-[16px] flex flex-col justify-center hover:bg-black-5"}
            >
              <AppText type={"BODY_MEDIUM"}>{t.label}</AppText>
            </div>
          ))}
    </div>
  );
};

export default React.memo(Dropdown);
