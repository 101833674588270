/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import Image from "next/image";
import { ChevronRight } from "lucide-react";

import AppText from "@/components/AppText";
import ContentLayout from "@/components/layouts/ContentLayout";
import { AppButton } from "@/components/buttons/AppButton";
import { AppIconButton } from "@/components/buttons/AppIconButton";
import { cn } from "@/lib/utils";
import RichTextRenderer from "./RichTextRenderer";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./ui/accordion";
import { colorsMap, fallbackColors } from "./constants";

interface AccordionContentProps {
  id?: string;
  label: string;
  heading: string;
  subheading?: string;
  ctaLabel?: string;
  ctaLink: string;
  image: string;
  placeholder: string;
  imgAlt: string;
  sections: any[];
  background: string;
  paddingBottom?: string;
}

const AccordionMain: React.FC<AccordionContentProps> = ({
  id,
  label,
  heading,
  ctaLabel,
  ctaLink,
  image,
  placeholder,
  imgAlt,
  sections,
  background,
  subheading,
  paddingBottom,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>();
  const { labelColor, textColor, bgColorClass, bg, border } = colorsMap[background] ?? fallbackColors;

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      {label && (
        <AppText type={"LABEL_LARGE"} className={labelColor}>
          {label}
        </AppText>
      )}

      <div className={"flex max-lg:flex-col justify-between mt-2"}>
        <div className={"flex flex-col"}>
          {heading && (
            <AppText type={"HEADLINE_MEDIUM"} className={cn("mr-10", textColor)}>
              {heading}
            </AppText>
          )}

          {subheading && (
            <AppText type={"SUB_HEADING_SMALL"} as="p" className={textColor}>
              {subheading}
            </AppText>
          )}
        </div>

        {ctaLabel && (
          <AppButton label={ctaLabel} link={ctaLink} intent={"secondary"} className={"max-lg:mt-8"} bg={bg} />
        )}
      </div>

      <div className="lg:grid-cols-12 lg:mt-16 grid grid-cols-4 mt-8">
        <div className={"flex flex-col col-span-4 lg:col-span-6 lg:mr-[40px] max-lg:mt-[24px]"}>
          <Accordion
            type="single"
            collapsible
            className="flex flex-col w-full gap-4"
            onValueChange={(e) => setActiveAccordion(e)}
          >
            {sections.map((section: any) => (
              <AccordionItem
                value={section.id}
                key={section.id}
                className={cn("border-transparent border-0 border-l-2 px-6", activeAccordion === section.id && border)}
              >
                <AccordionTrigger
                  size={24}
                  className={cn(
                    "underline-none overflow-hidden font-sairaSemiCondensed text-ellipsis w-full pt-0 hover:no-underline text-[20px] font-[600] uppercase tracking-[0.5px]",
                    textColor
                  )}
                >
                  {section.heading}
                </AccordionTrigger>
                <AccordionContent className="py-0">
                  <RichTextRenderer
                    richTextDocument={section.content}
                    id={section.id}
                    locations
                    className={cn("text-sm", textColor)}
                  />

                  {/** because of https://staging.awsdev.willscot.com/en/customizations/workspaces/cabinetry-other-furnishings
                   * some accordions do not get links, so don't render btn if there is no link
                   */}
                  {section.ctaLink && (
                    <AppIconButton
                      intent="ghost"
                      label={section.ctaLabel}
                      link={section.ctaLink}
                      icon={<ChevronRight size={20} aria-label="chevron right" />}
                      iconOnRightSide
                      bg={bg}
                      className={"mt-[24px]"}
                    />
                  )}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className="flex flex-col col-span-4 lg:col-span-6 order-first lg:order-last aspect-[16/9] relative">
          <Image
            src={image}
            alt={imgAlt}
            fill
            sizes="(max-width: 1024px) 100vw, 700px"
            className="object-cover w-full"
            placeholder="blur"
            blurDataURL={placeholder}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default React.memo(AccordionMain);
