/* eslint-disable @typescript-eslint/no-explicit-any */
import { isMobilePhone } from "validator";
import * as z from "zod";

/** Represents a form field with its properties */
interface Field {
  name: string;
  fieldType: string;
  required?: boolean;
  min?: number;
  max?: number;
  requiredError?: string;
  minError?: string;
  maxError?: string;
}

/** Represents a row of fields in a form */
interface Row {
  fields: Field[];
}

/** Represents a group of rows or a special field group in a form */
interface Group {
  groupType: string;
  rows?: Row[];
  requiredError?: string;
}

/**
 * Creates a dynamic Zod schema based on the provided data groups.
 * @param dataGroups - An array of Group objects representing the form structure
 * @returns A record of Zod schemas for each field
 */
export const createDynamicSchemaSupportThirdTab = (dataGroups: Group[]): Record<string, z.ZodTypeAny> => {
  const res: Record<string, z.ZodTypeAny> = {};

  dataGroups.forEach((group) => {
    if (group.groupType === "fieldGroupOptionCards") {
      res.cards = z.string().min(1, { message: group.requiredError });
      return;
    }

    group.rows?.forEach((row) => {
      row.fields?.forEach((field) => {
        res[field.name] = createFieldSchema(field);
      });
    });
  });

  return res;
};

export const createDynamicSchemaColumnForm = (fields: any) => {
  const res: Record<string, z.ZodTypeAny> = {};

  (fields || []).forEach((field: any) => {
    field.fieldType = field.itemType;
    res[field.name] = createFieldSchema(field);
  });

  return res;
};

/**
 * Creates a Zod schema for a specific field based on its type.
 * @param field - The field object containing field properties
 * @returns A Zod schema for the field
 */
const createFieldSchema = (field: Field): z.ZodTypeAny => {
  switch (field.fieldType) {
    case "fieldCalendar":
      return createCalendarSchema(field);
    case "fieldPhone":
      return createPhoneSchema(field);
    case "fieldText":
      return createTextSchema(field);
    case "fieldGoogleAddress":
      return createGoogleAddressSchema(field);
    case "fieldDropdown":
      return createDropdownSchema(field);
    case "fieldEmail":
      return createEmailSchema(field);
    case "fieldTextArea":
      return createTextAreaSchema(field);
    default:
      return z.any();
  }
};

const createCalendarSchema = (field: Field): z.ZodTypeAny => {
  return z.date({ invalid_type_error: field.requiredError });
};

const createPhoneSchema = (field: Field): z.ZodTypeAny => {
  const baseSchema = z.string().max(256).refine(isMobilePhone, { message: "Invalid phone number" });
  return field.required ? baseSchema : baseSchema.optional().or(z.literal(""));
};

const createTextSchema = (field: Field): z.ZodTypeAny => {
  if (field.name === "Your best offer") {
    return z.coerce
      .number({ invalid_type_error: "Price must be a number" })
      .refine((val) => `${val}`.length <= (field.max || 20), field.maxError || "Maximum 20 characters")
      .optional();
  }

  if (field.name.endsWith("_zip")) {
    return z
      .string()
      .refine(
        (value) =>
          /(^\d{5}$)|(^\d{5}-\d{4}$)|(^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$)/i.test(
            value
          ),
        { message: field.requiredError || "Invalid ZIP code" }
      );
  }

  const baseSchema = z.string().max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
  return field.required
    ? baseSchema.min(field.min || 2, { message: field.minError || field.requiredError })
    : baseSchema.optional();
};

const createGoogleAddressSchema = (field: Field): z.ZodTypeAny => {
  return z
    .string()
    .min(field.min || 2, { message: field.minError || field.requiredError })
    .max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
};

const createDropdownSchema = (field: Field): z.ZodTypeAny => {
  const baseSchema = z.string().max(field.max || 256, { message: field.maxError || "Maximum 256 characters" });
  return field.required
    ? baseSchema.min(field.min || 1, { message: field.minError || field.requiredError })
    : baseSchema.optional();
};

const createEmailSchema = (field: Field): z.ZodTypeAny => {
  const baseSchema = z
    .string()
    .max(256)
    .email({ message: field.requiredError || "Invalid email address" });
  return field.required ? baseSchema : z.string().max(0).or(baseSchema);
};

const createTextAreaSchema = (field: Field): z.ZodTypeAny => {
  const baseSchema = z.string().max(field.max || 2000, { message: field.maxError || "Maximum 2000 characters" });
  return field.required
    ? baseSchema.min(field.min || 1, { message: field.minError || field.requiredError })
    : baseSchema.optional();
};
