/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import ContentLayout from "../layouts/ContentLayout";
import {
  ESG_GROUPS_MAPPING,
  ESG_IMPACT_GROUPS,
  INITIAL_ESG_IMPACT_PAGINATION,
  INITIAL_SEARCH_RESULTS,
  INITIAL_SEARCH_TOTAL,
  ITEMS_PER_PAGE,
} from "../constants";
import { AppButton } from "../buttons/AppButton";
import { cn } from "@/lib/utils";
import MobileDropdown from "../globalSearch/MobileDropdown";
import Pagination from "../Pagination";
import CustomLink from "../CustomLink";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";

import AppText from "../AppText";
import { ChevronRight } from "lucide-react";
import { esgMapping } from "@/lib/esgmaping";

interface IESGReportsList {
  dict: { [key: string]: string };
  initial: {
    allPrograms: { [key: string]: string }[];
    environmental: { [key: string]: string }[];
    social: { [key: string]: string }[];
    governance: { [key: string]: string }[];
  };
  initialTotal: {
    allPrograms: number;
    environmental: number;
    social: number;
    governance: number;
  };
  error?: any;
  paddingBottom?: string;
}

const ESGReportsList = ({ dict, initial, error, initialTotal, paddingBottom }: IESGReportsList) => {
  const [searchResults, setSearchResults] = useState<any>(initial);
  const [currentPage, setCurrentPage] = useState<any>(INITIAL_ESG_IMPACT_PAGINATION);
  const [total, setTotal] = useState<{ [key: string]: number }>(initialTotal);
  const [activeGroup, setActiveGroup] = useState("allPrograms");
  const first = useRef(false);

  const lastPage = Math.ceil(total[activeGroup] / ITEMS_PER_PAGE);

  useEffect(() => {
    setTimeout(() => {
      first.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    if (!currentPage || !first.current) return;

    (async () => {
      try {
        const res = await fetchContentfulESG(
          (currentPage[activeGroup] - 1) * ITEMS_PER_PAGE,
          activeGroup,
          dict.read_more
        );

        if (res.error) {
          console.log(res.error || res.error.message);
          setSearchResults(INITIAL_SEARCH_RESULTS);
          setTotal(INITIAL_SEARCH_TOTAL);
          // ! throw toast?!

          return;
        }

        if (res) {
          setSearchResults((prev: any) => ({ ...prev, [res.activeGroup]: res.res }));
          setTotal((prev: any) => ({ ...prev, [res.activeGroup]: res.total }));
        }
      } catch (err) {
        console.log("Error occurred when fetching search results on pagination");
        console.log(err);
      } finally {
        window.scrollTo(0, 0);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSetCurrentPage = (page: number) => {
    const _currentPage = { ...currentPage };

    _currentPage[activeGroup] = page;
    setCurrentPage(_currentPage);
  };

  const handleGroupClick = (group: string) => {
    setActiveGroup(group);
  };

  return (
    <div className={cn("flex flex-col", paddingBottom)}>
      <ContentLayout bgColorClass="bg-black-10 z-10" innerClass="sm:py-2 py-2 px-0">
        <div className="lg:flex gap-x-10 flex-wrap justify-center hidden w-full">
          {ESG_IMPACT_GROUPS.map((res: string) => (
            <AppButton
              intent="ghost"
              label={`${dict[res]} (${total[res]})`}
              key={res}
              onClick={() => handleGroupClick(res)}
              className={cn("font-saira font-[400]", activeGroup === res && "font-[500] after:scale-x--100")}
            />
          ))}
        </div>
        <div className="lg:hidden flex">
          <MobileDropdown
            groups={ESG_IMPACT_GROUPS}
            dict={dict}
            total={total}
            setActiveGroup={setActiveGroup}
            activeGroup={activeGroup}
          />
        </div>
      </ContentLayout>

      {/** CARDS */}

      <ContentLayout>
        {error && <div>Error occurred!</div>}
        <div className="sm:grid-cols-8 lg:grid-cols-12 lg:gap-10 grid grid-cols-4 gap-4">
          {searchResults[activeGroup] &&
            searchResults[activeGroup].map((res: any) => <ESGCard key={res.id} {...res} />)}
        </div>
      </ContentLayout>

      {total[activeGroup] > 12 && (
        <div className="flex justify-center px-6 py-[48px] mt-8">
          <Pagination
            currentPage={currentPage[activeGroup]}
            lastPage={lastPage}
            setCurrentPage={handleSetCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default ESGReportsList;

interface IESGCard {
  id: string;
  name: string;
  ctaLabel: string;
  slug: string;
  category: string;
  featuredImage: string;
  imgAlt: string;
}
const ESGCard = ({ id, name, ctaLabel, slug, category, featuredImage, imgAlt }: IESGCard) => {
  return (
    <CustomLink id={id} href={slug} className={cn(`flex flex-col overflow-hidden transition group col-span-4`)}>
      <div className="relative w-full overflow-hidden">
        <Image
          className="object-cover w-full group-hover:scale-105 transition aspect-[1.75] md:aspect-[1.5] xl:aspect-[1.75]"
          sizes="100vw"
          width={0}
          height={0}
          src={featuredImage || "/placeholders/no-preview.png"}
          alt={imgAlt}
        />
      </div>

      <div className={cn("flex flex-col items-start flex-1 py-4")}>
        {category && (
          <AppText className={cn("mb-2 text-royal-blue")} type="LABEL_LARGE">
            {category}
          </AppText>
        )}

        <AppText className={cn("line-clamp-2 mb-4")} type="TITLE_SMALL">
          {name}
        </AppText>

        <AppIconButton
          tabIndex={-1}
          intent="ghost"
          label={ctaLabel}
          icon={<ChevronRight size={20} aria-label="chevron right icon" />}
          iconOnRightSide
          className="mt-auto"
        />
      </div>
    </CustomLink>
  );
};

export const fetchContentfulESG = async (skip: number, category: string, read_more: string) => {
  const payload: any = {
    content_type: "esgImpactArticle",
    include: 10,
    limit: ITEMS_PER_PAGE,
    skip,
  };

  if (ESG_GROUPS_MAPPING[category as keyof typeof ESG_GROUPS_MAPPING]) {
    const validCat: any = ESG_GROUPS_MAPPING[category as keyof typeof ESG_GROUPS_MAPPING];

    payload["fields.category"] = validCat;
  }

  try {
    const data = await fetch("/api/contentful", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const res = await data.json();

    if (res.error) {
      return { res: {}, activeGroup: category, total: 0, error: res.error.message };
    }

    const mapping = esgMapping(res, read_more);

    return { res: mapping, activeGroup: category, total: res.total };
  } catch (error: any) {
    console.log(error);

    return { res: {}, activeGroup: category, total: 0, error: error.message };
  }
};
