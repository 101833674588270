export const mockCaseStudyList = {
  CaseStudyList: true,
  storybook: true,
  dict: {
    // noResultsFor: "No results for",
    // results: "results",
    // result: "result",
    read_more: "Read more",
  },
  preview: false,
  industries: [
    {
      name: "All Industries",
      value: "allIndustries",
    },
  ],
  solutions: [
    {
      name: "All Solutions",
      value: "allSolutions",
    },
  ],
  list: [
    {
      id: "6vVk4uCbA217GYiQm0ImK0",
      brandSrc:
        "https://wsmm.bynder.com/transform/28eb6d93-d278-4ff9-b51f-ca3983862ada/Web?io=transform:fit,height:82,width:159",
      imgSrc:
        "https://wsmm.bynder.com/transform/c525b77c-8271-4d4d-a9fd-113ed09ce4cb/Little-Rock-Central-HS_Classroom_Ext-41_2023_US?io=transform:fit,height:309,width:540",
      title: "2019 PGA Championship",
      label: "Sports",
      content:
        "The PGA needed temporary workspace for this tmajor sporting event, so WillScot brought their A game – with 80 temporary mobile offices for event planners, vendors and media outlets.",
      articleUrl: "/resources/case-studies/2019-pga-championship",
      buttonLabel: "Read more",
      type: "caseStudy",
    },
    {
      id: "3NdWKWOVNhFwSmnix2nXcx",
      brandSrc:
        "https://wsmm.bynder.com/transform/28eb6d93-d278-4ff9-b51f-ca3983862ada/Web?io=transform:fit,height:82,width:159",
      imgSrc:
        "https://wsmm.bynder.com/transform/55e760f3-d63c-4b75-8373-bd89c4ac604d/Carmel_Construction_Int-01_2023_US?io=transform:fit,height:309,width:540",
      title: "Bi-Coastal Covid-19 Testing and Vaccination Sites",
      label: "Pharmaceutical",
      content:
        "Amidst the ongoing global pandemic, a healthcare provider found itself in need of temporary COVID-19 testing sites to help keep patients and staff safe. With offices across the U.S., they needed modern, multi-functional workspace on both coasts that could meet their changing with their needs.",
      articleUrl: "/resources/case-studies/bi-coastal-covid-19-testing-and-vaccination-sites",
      buttonLabel: "Read more",
      type: "caseStudy",
    },
    {
      id: "CKXNOQwIZXQZ425tw3CWC",
      brandSrc:
        "https://wsmm.bynder.com/transform/28eb6d93-d278-4ff9-b51f-ca3983862ada/Web?io=transform:fit,height:82,width:159",
      imgSrc:
        "https://wsmm.bynder.com/transform/c6128a92-3c91-4bff-a795-55f1d4de90bd/Events-Entertainment-Ext-02_2023_US_Rendering?io=transform:fit,height:309,width:540",
      title: "Baltimore Museum of Art",
      label: "Events & Entertainment",
      content:
        "The Baltimore Museum of Art was in need of several temporary buildings to create the foundation for an upcoming exhibit “Mickalene Thomas: A Moment’s Pleasure.",
      articleUrl: "/resources/case-studies/baltimore-museum-of-art",
      buttonLabel: "Read more",
      type: "caseStudy",
    },
  ],
  totalSum: 3,
  id: "B6tifTOj6KgRN38CBYNZS",
};

export const mockIndustryList = {
  IndustryList: true,
  industries: [
    {
      id: "6xPp3RJTEXPbE3CDSZdZd1",
      imgSrc: "https://wsmm.bynder.com/transform/ca450671-5215-4d85-ab25-91f0357c9cb5/Arial-Branch-Perspective-01",
      buttonLabel: "Aerospace",
      articleUrl: "/industries/aerospace",
      type: "industry",
    },
    {
      id: "5mQjuAWJHPq8M4lqzINnif",
      imgSrc:
        "https://wsmm.bynder.com/transform/c525b77c-8271-4d4d-a9fd-113ed09ce4cb/Little-Rock-Central-HS_Classroom_Ext-41_2023_US",
      buttonLabel: "Sports",
      articleUrl: "/industries/sports",
      type: "industry",
    },
    {
      id: "7pNQYum804Vdt2qKkONE8a",
      imgSrc: "https://wsmm.bynder.com/transform/4c3a54af-bb57-44f6-b60b-c6c49e742cc4/Food-Bank-01",
      buttonLabel: "Retail & Wholesale",
      articleUrl: "/industries/retail-and-wholesale",
      type: "industry",
    },
    {
      id: "4dQTEOxz3JH4QURAJuVVdk",
      imgSrc: "https://wsmm.bynder.com/transform/a254d420-a5f4-4fa6-b62d-d511695dfd88/Cold_Storage_VA_Ext-27_2023_US",
      buttonLabel: "Religion",
      articleUrl: "/industries/religion",
      type: "industry",
    },
    {
      id: "5XjHa3NlS0pPlkKAytgQUV",
      imgSrc: "https://wsmm.bynder.com/transform/dbe7f585-ea2e-4b1f-ae75-5943703bf06e/Emergent-Bio-FLEX-08",
      buttonLabel: "Professional Services",
      articleUrl: "/industries/professional-services",
      type: "industry",
    },
    {
      id: "4ITO18grFRTIg4uXaIfY6s",
      imgSrc: "https://wsmm.bynder.com/transform/d50735f1-2993-4b9c-97ea-2196689e49b8/191127-WillScot-BMA-010",
      buttonLabel: "Pharmaceutical",
      articleUrl: "/industries/pharmaceutical",
      type: "industry",
    },
    {
      id: "5ofFVPiPP71mcR7EMJkBkn",
      imgSrc: "https://wsmm.bynder.com/transform/9eb5f09c-8ee4-4901-9069-921c5430194b/Hero-01_2022_US_BRM",
      buttonLabel: "Petrochemical",
      articleUrl: "/industries/petrochemical",
      type: "industry",
    },
    {
      id: "7Ou4164WX7yISOyWemcc0",
      imgSrc: "https://wsmm.bynder.com/transform/6799b6f6-ab13-4d1a-a282-1947f830fbe5/Go-Beyond-Branding-01",
      buttonLabel: "Military",
      articleUrl: "/industries/military",
      type: "industry",
    },
    {
      id: "4qftLEuomiRWiwwDYb28j4",
      imgSrc: "https://wsmm.bynder.com/transform/8c015b99-4cc3-4319-9d55-07abd14907f8/Manufacturing_Ext-02_2023_US",
      buttonLabel: "Manufacturing",
      articleUrl: "/industries/manufacturing",
      type: "industry",
    },
    {
      id: "6bNiAh8gTtr9E8ADXt7SSc",
      imgSrc:
        "https://wsmm.bynder.com/transform/e1fb7fd5-f981-4af6-91d3-9d0db20776ca/Container_Ramp_Austin_Ext-05_2023_US",
      buttonLabel: "Industrial & Commercial",
      articleUrl: "/industries/industrial-and-commercial",
      type: "industry",
    },
    {
      id: "6rBQ84Pd4NXPlL9VUhQ8nC",
      imgSrc: "https://wsmm.bynder.com/transform/fb92f104-5bc8-4004-a4db-bd39a41c169f/Cold_Storage_VA_Ext-02_2023_US",
      buttonLabel: "Healthcare",
      articleUrl: "/industries/healthcare",
      type: "industry",
    },
    {
      id: "4yPqvhO9YrIq0Mar1D2BA6",
      imgSrc:
        "https://wsmm.bynder.com/transform/e1fb7fd5-f981-4af6-91d3-9d0db20776ca/Container_Ramp_Austin_Ext-05_2023_US",
      buttonLabel: "Government",
      articleUrl: "/industries/government",
      type: "industry",
    },
    {
      id: "4zNtO5kXHKD3nL7EmLzUIJ",
      imgSrc: "https://wsmm.bynder.com/transform/454757c2-5b14-47ce-a23e-9190d10bc143/Mobile-Loaves-Fishes-07",
      buttonLabel: "Food Service",
      articleUrl: "/industries/food-service",
      type: "industry",
    },
    {
      id: "2PYGnuWTs29ZVHJNQOnraB",
      imgSrc: "https://wsmm.bynder.com/transform/83fc4a1f-1421-49a0-9c64-347217b1c0fb/F1_Vegas_FLEX_Ext-05_2023_US",
      buttonLabel: "Events & Entertainment",
      articleUrl: "/industries/events-and-entertainment",
      type: "industry",
    },
    {
      id: "70uuNTXZXC1223wFMGMtxq",
      imgSrc: "https://wsmm.bynder.com/transform/90741084-78ae-44d9-91eb-54681b44f743/Renewables-Vertical-Rendering",
      buttonLabel: "Engineering & Architecture",
      articleUrl: "/industries/engineering-and-architecture",
      type: "industry",
    },
    {
      id: "2xIEIJuiN6oJKL89HjoKeq",
      imgSrc: "https://wsmm.bynder.com/transform/7e7abc21-f798-4157-8865-ed0161cff286/Solar_Austin_Ext-07_2023_US",
      buttonLabel: "Energy, Mining & Utilities",
      articleUrl: "/industries/energy-mining-and-utilities",
      type: "industry",
    },
    {
      id: "6RrfVnx8zK3tQf0H7L6prl",
      imgSrc: "https://wsmm.bynder.com/transform/dfcc7606-7406-4022-8ad7-5917fe288ff8/Howard_University_Int-02_2023_US",
      buttonLabel: "Education",
      articleUrl: "/industries/education",
      type: "industry",
    },
    {
      id: "4zsQtfoTYqlY1p3ajvDeIo",
      imgSrc: "https://wsmm.bynder.com/transform/71dd4a83-6162-465f-8ac2-f9536ceb2062/Cold_Storage_VA_Int-09_2023_US",
      buttonLabel: "Construction",
      articleUrl: "/industries/construction",
      type: "industry",
    },
    {
      id: "1DWL64NaUf7ANoIno14mWt",
      imgSrc: "https://wsmm.bynder.com/transform/eb4dd3cb-01f9-41a8-bf88-8da900dca2e7/Cold_Storage_VA_Ext-17_2023_US",
      buttonLabel: "Automotive",
      articleUrl: "/industries/automotive",
      type: "industry",
    },
    {
      id: "2IReBoBJLXBuFYUL9UsKP7",
      imgSrc: "https://wsmm.bynder.com/transform/1d9866b5-1299-469a-85d5-9a451191179d/LuHi_High_Ext-02_2023_US",
      buttonLabel: "Agriculture",
      articleUrl: "/industries/agriculture",
      type: "industry",
    },
  ],
  title: "A Modular Solution for every Industry",
  content: "Lorem ipsum dolor sit amet consectetur. Diam ut nullam donec ac suspendisse pellentesque elit leo.",
  id: "24br8N26ycB9Komr5QKvxo",
};

export const mockFAQsList = {
  storybook: true,
  FaqList: true,
  id: "7yN39PKSKJo5I1MaIgJ846",
  list: [
    {
      id: "3OoNbeXmaxwwK1M7dy8vpL",
      title: "FAQ Topic 1",
      ctaUrl: "/resources/faqs/faq-topic-1",
      excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa. ",
    },
    {
      id: "1deaS6iPtvjzjwMlmWkobf",
      title: "FAQ Topic 2",
      ctaUrl: "/resources/faqs/faq-topic-2",
      excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa. ",
    },
    {
      id: "5W33DIuKS7KTWUgHiRnM8p",
      title: "FAQ Topic 3",
      ctaUrl: "/resources/faqs/faq-topic-3",
      excerpt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa. ",
    },
  ],
  ctaLabel: "View FAQs",
};

export const mockBogsList = {
  storybook: true,
  BlogsList: true,
  dict: {
    read_more: "Read more",
    noResultsFor: "No results for",
    result: "result",
    results: "results",
  },
  preview: false,
  categories: [
    {
      name: "All Categories",
      value: "allCategories",
    },
    {
      name: "Construction Landscape",
      value: "Construction Landscape",
    },
  ],
  list: [
    {
      id: "2G8D8CnsXXiucOxyUqLukI",
      imgSrc:
        "https://wsmm.bynder.com/transform/069dc273-113f-4436-bb36-f8b1abe8b4e8/Cold_Storage_VA_Ext-20_2023_US?io=transform:fit,height:309,width:540",
      title: "5 ways to prepare your jobsite for winter weather",
      date: "2024-02-19T11:40:13.996Z",
      content: "",
      articleUrl: "/resources/blogs/5-ways-to-prepare-your-jobsite-for-winter-weather",
      buttonLabel: "Read more",
      type: "blog",
    },
    {
      id: "3r2kfTnzmJkPIvrohEK5e3",
      imgSrc:
        "https://wsmm.bynder.com/transform/d10eeba1-691c-44f5-be06-1578534bd9ea/FLEX_2-Story_AZ_Int-09_2023_US?io=transform:fit,height:309,width:540",
      title: "How Flex™ mobile offices can improve jobsite safety",
      date: "2024-02-07T13:41:17.137Z",
      content: "",
      articleUrl: "/resources/blogs/how-flex-tm-mobile-offices-can-improve-jobsite-safety",
      buttonLabel: "Read more",
      type: "blog",
    },
    {
      id: "4zXxFX5ytVJ1WPLLBSpTl",
      imgSrc:
        "https://wsmm.bynder.com/transform/a564307b-c7fe-4ec3-8746-ed85170b8e81/Container_Ramp_Austin_Ext-04_2023_US?io=transform:fit,height:309,width:540",
      title: "4 storage tips to help you prepare for the holiday retail season",
      date: "2024-02-07T13:38:03.275Z",
      content: "",
      articleUrl: "/resources/blogs/4-storage-tips-to-help-you-prepare-for-the-holiday-retail-season",
      buttonLabel: "Read more",
      type: "blog",
    },
  ],
  totalSum: 3,
  id: "1TQpw92Hv7yEunwkgWzX8U",
};

export const mockCategoryIndexList = {
  CategoryIndexList: true,
  list: [
    {
      id: "2G8D8CnsXXiucOxyUqLukI",
      imgSrc:
        "https://wsmm.bynder.com/transform/069dc273-113f-4436-bb36-f8b1abe8b4e8/Cold_Storage_VA_Ext-20_2023_US?io=transform:fit,height:309,width:540",
      title: "FLEX",
      content:
        "For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution. For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution ",

      articleUrl: "/resources/blogs/5-ways-to-prepare-your-jobsite-for-winter-weather",
      buttonLabel: "Read more",
      type: "solution",
    },
    {
      id: "3r2kfTnzmJkPIvrohEK5e3",
      imgSrc:
        "https://wsmm.bynder.com/transform/d10eeba1-691c-44f5-be06-1578534bd9ea/FLEX_2-Story_AZ_Int-09_2023_US?io=transform:fit,height:309,width:540",
      title: "Workforce Housing",
      content:
        "For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution. For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution ",
      buttonLabel: "Read more",
      articleUrl: "/resources/blogs/how-flex-tm-mobile-offices-can-improve-jobsite-safety",

      type: "solution",
    },
    {
      id: "3r2kfTnzmsddasdJkPIvrohEK5e3",
      imgSrc:
        "https://wsmm.bynder.com/transform/d10eeba1-691c-44f5-be06-1578534bd9ea/FLEX_2-Story_AZ_Int-09_2023_US?io=transform:fit,height:309,width:540",
      title: "Ready-Plex Offices",
      content:
        "For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution. For a marketing trailer to be used as a pre-leasing office for a new high-rise apartment building, Beatty Development called on WillScot for a quick and inexpensive Flex office solution ",
      buttonLabel: "Read more",
      articleUrl: "/resources/blogs/how-flex-tm-mobile-offices-can-improve-jobsite-safety",

      type: "solution",
    },
  ],
  label: "Solutions",
  heading: "Made to Order Solutions",
  subheading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit proin ut luctus la",
  id: "1hvUxK76wqB4LXPastoz7W",
};

export const mockOutfittingPackagesList = {
  storybook: true,
  OutfittingPackagesList: true,
  dict: {
    read_more: "Read more",
    noResultsFor: "No results for",
    result: "result",
    results: "results",
  },
  preview: false,
  industries: [
    {
      name: "All Industries",
      value: "allIndustries",
    },
    {
      name: "Construction Landscape",
      value: "Construction Landscape",
    },
  ],
  list: [
    {
      id: "6DR81CfJA5MYHm2VpNTPLG",
      title: "Workstation Packages",
      articleUrl: "/solutions/outfittings/workstation-packages",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc: "https://wsmm.bynder.com/transform/8cef4974-bd0a-49fd-bc19-55cc0f6dbd2b/FLEX_2-Story_AZ_Int-14_2023_US",
      buttonLabel: "View Package",
      type: "solution",
    },
    {
      id: "7zkLtpwYQZjUpDrLRFVv6G",
      title: "Storage Shelving",
      articleUrl: "/solutions/outfittings/storage-shelving",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc:
        "https://wsmm.bynder.com/transform/1f034f26-23b0-4697-a7a1-9e2c0a572751/40x8_Storage_Container_BOD_Int-02_2023_US",
      buttonLabel: "View Package",
      type: "solution",
    },
    {
      id: "2rhWZ8ZdIitC5NPdtB5DW6",
      title: "Storage Packages",
      articleUrl: "/solutions/outfittings/storage-packages",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc: "https://wsmm.bynder.com/transform/302f85f9-ae48-4d3e-a912-f769e1e7f07c/Cold_Storage_VA_Int-15_2023_US",
      buttonLabel: "View Package",
      type: "solution",
    },
    {
      id: "6IEQfXvHbdlSbuFfEmTAi7",
      title: "Storage Lighting",
      articleUrl: "/solutions/outfittings/storage-lighting",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc: "https://wsmm.bynder.com/transform/0e57d916-6560-4886-aebe-0517256e69fe/Cold_Storage_VA_Int-04_2023_US",
      buttonLabel: "View Package",
      type: "solution",
    },
    {
      id: "3rzgPNBttzG2HoYn0xh1fW",
      title: "Site Additions",
      articleUrl: "/solutions/outfittings/site-additions",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc: "https://wsmm.bynder.com/transform/b89e93ed-0506-424f-8959-c50f40ff6ee0/Solar-Jobsite-02_2023_US",
      buttonLabel: "View Package",
      type: "solution",
    },
    {
      id: "5Ezu5ffVGRB7R10MPpFdXu",
      title: "Protection Plans",
      articleUrl: "/solutions/outfittings/protection-plans",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut luctus lacus, eget dapibius massa.",
      imgSrc:
        "https://wsmm.bynder.com/transform/145458d5-9450-4d36-9f22-d649708ead93/Protection-Plans-01_2022_US_Essentials",
      buttonLabel: "View Package",
      type: "solution",
    },
  ],
  totalSum: 6,
  id: "1TQpw92Hv7yEunwkgWzX8U",
};

export const mockDocumentAndResourcesList = {
  storybook: true,
  DocumentsAndResourcesList: true,
  dict: {
    read_more: "Read more",
    allIndustries: "All Industries",
    allSolutions: "All Solutions",
    read_article: "Read article",
    noResultsFor: "No results for",
    noResults: "No results",
  },
  preview: false,
  industries: [
    {
      name: "Aerospace",
      value: "6xPp3RJTEXPbE3CDSZdZd1",
    },
    {
      name: "Agriculture",
      value: "2IReBoBJLXBuFYUL9UsKP7",
    },
  ],
  topics: [
    {
      name: "Topic 1",
      value: "Topic 1",
    },
    {
      name: "Topic 2",
      value: "Topic 2",
    },
    {
      name: "Topic 3",
      value: "Topic 3",
    },
  ],
  list: [
    {
      id: "6dA4IYgNXKydcBeiJKXXJG",
      title: "Mobile Office Trailer Rental - Overview and Common uses",
      imgSrc:
        "https://wsmm.bynder.com/transform/a254d420-a5f4-4fa6-b62d-d511695dfd88/Cold_Storage_VA_Ext-27_2023_US?io=transform:fit,height:530,width:630",
      type: "doc",
      articleUrl: "/documents/mobile-office-trailer-rental-overview-and-common-uses",
      buttonLabel: "Read more",
    },
    {
      id: "15URIWluDLCeHOhdNJfwa5",
      title: "Brand Guidelines",
      imgSrc:
        "https://wsmm.bynder.com/transform/3d7b4894-675a-4c6b-8d6a-80c3eb108275/Wynn_High_School_Austin_Ext-02_2023_US?io=transform:fit,height:530,width:630",
      type: "doc",
      articleUrl: "#",
      buttonLabel: "Download",
    },
  ],
  totalSum: 2,
  id: "DJUxzyWHdQNKPjlfJwK2B",
};
