/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useMemo, useState } from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import RichTextRenderer from "../RichTextRenderer";
import { cn } from "@/lib/utils";
import { SwiperSlide } from "swiper/react";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";

import { Swiper } from "swiper/react";
import { useSwiper } from "@/hooks/useSwiper";
import { SwiperOptions } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface IFeature {
  id: string;
  label: string;
  icon: string;
}

interface IColumn {
  id: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  image?: string;
  imgAlt?: string;
  features?: IFeature[];
}

interface IProductFeatureConfiguration {
  id: string;
  heading: string;
  content: any;
  showGallery: boolean;
  // ctaLabel: string;
  // ctaLink: string;
  columns: IColumn[];
  paddingBottom?: string;
}

const renderImage = (imageUrl: { url: string; alt: string }, key: number) => {
  return (
    <SwiperSlide key={key} className="px-[0px] sm:px-[8px] h-auto">
      <Image
        src={imageUrl.url}
        alt={imageUrl.alt}
        sizes="100vw"
        width={0}
        height={0}
        className="aspect-1.7 object-contain w-full max-h-[263px]"
      />
    </SwiperSlide>
  );
};

const ProductFeatureConfiguration = ({
  id,
  heading,
  content,
  showGallery,
  // ctaLabel,
  // ctaLink,
  columns = [],
  paddingBottom,
}: IProductFeatureConfiguration) => {
  const imageUrls: { url: string; alt: string }[] = useMemo(
    () =>
      columns.map((col: IColumn) => ({ url: col.image || "/placeholders/no-preview.png", alt: col.imgAlt || "image" })),
    [columns]
  ).filter((item) => item);
  const [boxClick, setBoxClick] = useState<number>(0);
  const [active, setActive] = useState<number | null>(null);

  const setNewPage = (page: number) => setActive(page - 1);

  const handleBoxClick = (index: number) => {
    if (index !== active) {
      setBoxClick(index);
    }
  };

  const handleBoxKeyDown = (key: string, index: number) => {
    if (index !== active && key === "Enter") {
      setBoxClick(index);
    }
  };

  return (
    <ContentLayout bgColorClass="bg-navy-blue" id={id} innerClass="sm:py-12 py-6" paddingBottom={paddingBottom}>
      <div className="flex flex-col items-center">
        <div className=" flex flex-col items-center">
          <AppText type="HEADLINE_MEDIUM" className="mb-4 text-center text-white">
            {heading}
          </AppText>

          <RichTextRenderer
            richTextDocument={content}
            id={id + "content"}
            locations
            className={cn("text-white text-center")}
          />
          {/* <AppButton label={ctaLabel} link={ctaLink} bg="dark" className="mt-2 mb-6" /> */}
        </div>
      </div>

      {!!imageUrls.length && showGallery && (
        <div className="relative mb-10 overflow-hidden">
          <Carousel
            className="bg-navy-blue"
            // className={cn("flex-1 flex items-stretch w-screen relative left-1/2 -translate-x-1/2 !pb-10 bg-navy-blue")}
            loop={false}
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={500}
            centeredSlides={true}
            rewind={true}
            watchOverflow={true}
            watchSlidesProgress={true}
            setNewPage={setNewPage}
            boxClick={boxClick}
          >
            {imageUrls.map(renderImage)}
          </Carousel>
        </div>
      )}

      {columns[0] ? (
        columns[0]?.features ? (
          <div className="lg:flex-row flex flex-col justify-center flex-1 gap-8">
            {columns.map((col, idx) => {
              // const isLast = columns.length === idx + 1;
              return (
                <div key={idx} className="flex-1 max-w-full lg:max-w-[400px]">
                  <div
                    className={cn(
                      "px-4 gap-4 pt-4 pb-3 border-[1px] border-navy-blue-80 bg-[#fcfcfc0d] flex  items-center flex-col"
                      // !isLast && "mr-0 lg:mr-8"
                    )}
                    key={idx}
                  >
                    <AppText
                      type="TITLE_X_SMALL"
                      className={cn("text-center", active === idx ? "text-mellow" : "text-white")}
                    >
                      {col.heading}
                    </AppText>

                    {col.subheading && (
                      <AppText type="BODY_SMALL" className="mb-auto text-center text-white">
                        {col.subheading}
                      </AppText>
                    )}

                    <AppIconButton
                      link={col.ctaLink}
                      iconOnRightSide
                      icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                      intent={"ghost"}
                      label={col.ctaLabel}
                      className="after:left-0 after:right-0 w-full px-0"
                      bg={"dark"}
                    />

                    <div className="lg:hidden flex flex-col flex-1 w-full mt-2">
                      <Accordion type="single" collapsible className="flex flex-col w-full gap-4">
                        <AccordionItem
                          value={"accordion" + idx}
                          key={"accordion" + idx}
                          className={cn("border-transparent border-2 w-full")}
                        >
                          <AccordionTrigger
                            size={24}
                            className={cn(
                              "underline-none w-fit text-white gap-4 justify-center overflow-hidden font-sairaSemiCondensed text-ellipsis py-0 hover:no-underline text-[14px] font-[500]"
                            )}
                          >
                            Features
                          </AccordionTrigger>

                          <AccordionContent className="py-0 grid grid-cols-8 gap-[10px] w-full pt-[10px]">
                            {(col?.features || []).map((feat: IFeature) => (
                              <div key={feat.id} className="bg-navy-blue flex flex-col col-span-4 gap-3 px-4 py-6">
                                <div className="flex justify-center">
                                  <Image
                                    src={feat.icon}
                                    alt="feature icon"
                                    sizes="100vw"
                                    width={0}
                                    height={0}
                                    className="w-6"
                                  />
                                </div>
                                <AppText type="BODY_SMALL" className="text-center text-white">
                                  {feat.label}
                                </AppText>
                              </div>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>

                  <div className="lg:flex flex-col hidden mt-2">
                    {(col?.features || []).map((feat: IFeature) => (
                      <div
                        key={feat.id}
                        className="flex flex-col justify-center px-4 py-6 border-b-[1px] border-navy-blue-80 gap-3"
                      >
                        <div className=" flex justify-center">
                          <Image
                            src={feat.icon}
                            alt="feature icon"
                            sizes="100vw"
                            width={0}
                            height={0}
                            className="w-6"
                          />
                        </div>
                        <AppText type="BODY_SMALL" className="text-center text-white">
                          {feat.label}
                        </AppText>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <ul
            className={cn(
              "xl:gap-10 gap-6",
              columns.length < 4 ? "flex flex-col sm:flex-row justify-center" : "grid grid-cols-4 sm:grid-cols-12"
            )}
          >
            {columns.map((col, idx) => {
              return (
                <li
                  tabIndex={0}
                  onKeyDown={(e) => handleBoxKeyDown(e.key, idx)}
                  key={idx}
                  className={cn(
                    "cursor-pointer outline-royal-blue",
                    columns.length < 4
                      ? "w-full sm:max-w-[302px] flex-1"
                      : "col-span-4 sm:col-span-6 md:col-span-4 xl:col-span-3"
                  )}
                  onClick={() => handleBoxClick(idx)}
                >
                  <div
                    className={cn(
                      "px-4 pt-4 pb-1.5 border-[1px] border-navy-blue-80 bg-[#fcfcfc0d] flex  items-center flex-col",

                      !col.features && active === idx && "border-mellow",
                      !col.features && "h-full"
                    )}
                    key={idx}
                  >
                    <AppText
                      type="TITLE_X_SMALL"
                      className={cn("text-center", active === idx ? "text-mellow" : "text-white")}
                    >
                      {col.heading}
                    </AppText>
                    {col.subheading && (
                      <AppText type="BODY_SMALL" className="mb-auto text-center text-white">
                        {col.subheading}
                      </AppText>
                    )}

                    <AppIconButton
                      link={col.ctaLink}
                      iconOnRightSide
                      icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                      intent={"ghost"}
                      label={col.ctaLabel}
                      className="after:left-0 after:right-0 w-full px-0"
                      bg={"dark"}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        )
      ) : null}
    </ContentLayout>
  );
};

export default ProductFeatureConfiguration;

interface CarouselProps {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  bg?: "dark" | "light" | undefined;
  cardsOverflow?: boolean;
  withoutWrapper?: boolean;
  setNewPage: (page: number) => void;
  boxClick: number;
}

// eslint-disable-next-line react/display-name
const Carousel = React.memo(
  ({
    children,
    wrapperClassName,
    className,
    setNewPage,
    boxClick,
    ...restProps
  }: CarouselProps & Omit<SwiperOptions, "onSwiper" | "onInit" | "onSlideChange" | "onBreakpoint">) => {
    const { swiperRef, onSwiperChange, currentPage, onNextPageClick, onPrevPageClick, setPage } = useSwiper();

    useEffect(() => {
      setNewPage(currentPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
      setPage(boxClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxClick]);

    return (
      <>
        <div className="relative">
          <div className={cn("relative", wrapperClassName)}>
            <Swiper
              className={className}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onInit={onSwiperChange}
              onSlideChange={onSwiperChange}
              onBreakpoint={onSwiperChange}
              {...restProps}
            >
              {children}
            </Swiper>
          </div>
        </div>

        {/** @ts-expect-error:children is array */}
        {children?.length > 1 && (
          <div className="absolute top-0 left-0 flex flex-col justify-center w-full h-full pb-10">
            <div className="w-full flex justify-between mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg sm:py-[80px] py-[48px]">
              <AppIconButton
                onClick={onPrevPageClick}
                icon={<ChevronLeft size={20} aria-label="chevron left icon" />}
                intent={"secondary"}
                size={"md"}
                title={"previous"}
                className={
                  "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
                }
              />
              <AppIconButton
                onClick={onNextPageClick}
                icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                intent={"secondary"}
                size={"md"}
                title={"next"}
                className={
                  "bg-white hover:!bg-black-10 active:!bg-black-20 shadow-lg border-none outline-none focus:ring-0 focus:ring-offset-0 w-[32px] h-[32px] p-1.5 z-10"
                }
              />
            </div>
          </div>
        )}
      </>
    );
  }
);
