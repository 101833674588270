/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";
import Carousel from "@/components/Carousel";
import Image from "next/image";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { ProductSolutionCard } from "@/mocks/mocks";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../constants";
import CustomLink from "../CustomLink";
import { useLocale } from "@/store/clientStore";
import { AppIconButton } from "../buttons/AppIconButton";

import { Separator } from "../ui/separator";
import { ChevronRight } from "lucide-react";

interface ProductSolutionsCardsProps {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLabel?: string;
  ctaLink?: string;
  solutions: ProductSolutionCard[];
  textAlign: string;
  ctaTheme: "primary" | "secondary" | "ghost" | undefined;
  postTypes?: string;
  background: string;
  paddingBottom?: string;
}

const ProductSolutionsCards: React.FC<ProductSolutionsCardsProps> = ({
  id,
  label,
  heading,
  subheading,
  ctaLabel,
  ctaLink,
  solutions,
  textAlign,
  ctaTheme = "primary",
  postTypes = "Solution",
  background,
  paddingBottom,
}) => {
  const { labelColor, bgColorClass, textColor, bg } = colorsMap[background] ?? fallbackColors;
  const { locale } = useLocale();

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} paddingBottom={paddingBottom}>
      <div className={cn("flex flex-col lg:flex-row gap-8 lg:gap-10")}>
        <div className={cn("flex flex-col w-full", textAlign === "Center" ? "items-center justify-center" : "")}>
          {label && (
            <AppText type={"LABEL_LARGE"} className={labelColor}>
              {label}
            </AppText>
          )}

          <AppText
            type={"HEADLINE_MEDIUM"}
            className={cn("text-white mt-[8px] line-clamp-2", textAlign === "Center" && "text-center", textColor)}
          >
            {heading}
          </AppText>

          <AppText
            type={"SUB_HEADING_SMALL"}
            className={cn("text-white mt-[16px] line-clamp-3", textAlign === "Center" && "text-center", textColor)}
          >
            {subheading}
          </AppText>
        </div>

        {ctaLabel && textAlign !== "Center" && (
          <div className={cn(textAlign === "Left" && "flex lg:justify-end")}>
            <AppButton className="lg:mt-8" label={ctaLabel} link={ctaLink} intent={ctaTheme} bg={bg} />
          </div>
        )}
      </div>

      <Carousel
        wrapperClassName={"mt-[56px] flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={bg}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 16,
            slidesPerGroup: 3,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 24,
            slidesPerGroup: 3,
          },
        }}
      >
        {solutions.map((product) =>
          renderProduct(product, postTypes, textAlign === "Center", locale, bg === "dark", labelColor, postTypes)
        )}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(ProductSolutionsCards);

const renderProduct = (
  product: any,
  type: string,
  isCentered: boolean,
  locale: string,
  darkBg: boolean,
  labelColor: string,
  postTypes: string
) => {
  const { image, id, ctaLink, title, imgAlt, subheading, ctaLabel, label, alternativeLabel, price } = product;

  return (
    <SwiperSlide key={id} className="p-2">
      <CustomLink
        href={ctaLink}
        lang={locale}
        className={cn(
          `cursor-pointer flex flex-col overflow-hidden ring-0 focus:outline-dijon outline-offset-4 
          transition h-[100%] group`
        )}
      >
        <div className={`relative w-full overflow-hidden`}>
          <Image
            className={cn(
              "object-cover w-full group-hover:scale-105 transition",
              postTypes === "Used Product" ? "aspect-[1.73]" : "aspect-[1]"
            )}
            sizes="100vw"
            width={0}
            height={0}
            src={image}
            alt={imgAlt}
          />
        </div>

        <div className={cn("flex flex-col items-start flex-1 py-6", isCentered && "items-center")}>
          {postTypes === "Used Product" && (
            <div className={cn("mb-4 flex flex-wrap gap-x-4 items-center")}>
              <span
                className={cn(
                  "font-saira text-14 leading-20 text-black font-semibold uppercase tracking-wider break-words",
                  labelColor
                )}
              >
                {label}
              </span>
              <Separator orientation="vertical" className="bg-black-60 h-7" />{" "}
              <span className={cn("text-black !font-normal text-[1rem] font-[400]", darkBg && "text-white")}>
                {alternativeLabel}
              </span>
            </div>
          )}

          <AppText
            className={cn("line-clamp-3 mb-4", darkBg && "text-white", isCentered && "text-center")}
            type="TITLE_LARGE"
          >
            {title}
          </AppText>

          {postTypes === "Used Product" ? (
            <AppText
              className={cn("line-clamp-5 mb-4", darkBg && "text-white", isCentered && "text-center")}
              type="BODY_LARGE_EMPHASIS"
            >
              {price}
            </AppText>
          ) : (
            <AppText
              className={cn("line-clamp-5 mb-4", darkBg && "text-white", isCentered && "text-center")}
              type="BODY_MEDIUM"
            >
              {subheading}
            </AppText>
          )}

          <AppIconButton
            tabIndex={-1}
            intent="ghost"
            title={ctaLabel}
            label={ctaLabel}
            icon={<ChevronRight size={20} aria-label="chevron right icon" />}
            iconOnRightSide
            className="mt-auto"
            bg={darkBg ? "dark" : "light"}
          />
        </div>
      </CustomLink>
    </SwiperSlide>
  );
};
