/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Form } from "@/components/ui/form";
import AppText from "@/components/AppText";
import AppButton from "@/components/buttons/AppButton";
import { preparePayloadRAQColumnForm } from "@/components/form/helpers";
import { FieldDropdown, FieldPhone, FieldText, FieldTextArea } from "./FormElements";
import { createDynamicSchemaColumnForm } from "./schemaHelper";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";

type Props = {
  id: string;
  background: string;
  subheading: string;
  originalFields: any;
};

const ColumnForm = ({ id, background, subheading, originalFields }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formSchema = z.object({});

  const [submitting, setSubmitting] = useState(false);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);

  const router = useRouter();

  const formDefaultValues = useMemo(() => {
    const res: any = {};

    (originalFields || []).forEach((field: any) => {
      res[field.name] = "";
    });

    return res;
  }, [originalFields]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaColumnForm(originalFields);

      const formSchema = z.object({
        ...zObj,
      });

      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [originalFields]);

  async function onSubmit() {
    if (!executeRecaptcha) {
      toast.error("Error submitting request - reCAPTCHA not loaded");
      return;
    }

    setSubmitting(true);

    const token = await executeRecaptcha("submit_request_quote");

    const formValues = form.getValues();

    const recaptchaResponse = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const recaptchaData = await recaptchaResponse.json();

    if (!recaptchaData.success) {
      console.log(recaptchaData);
      toast.error("reCAPTCHA verification failed");
      setSubmitting(false);
      return;
    }

    console.log("reCAPTCHA verification successful", recaptchaData);

    const payload = await preparePayloadRAQColumnForm(formValues);
    console.log("payload", payload);

    try {
      const response = await fetch("/api/request-quote", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      // request is valid if data is empty string
      if (data.error || data.message) {
        console.log(data);
        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push("/request-quote/thank-you");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  return (
    <div id={id} className={`bg-${background} w-full p-6`}>
      <AppText type="BODY_LARGE_EMPHASIS" className="text-pretty mb-10">
        {subheading}
      </AppText>
      <Form {...form}>
        <form className="flex flex-col gap-6" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
          {(originalFields || []).map((field: any) => {
            if (field.itemType === "fieldText") {
              return (
                <FieldText
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldPhone") {
              return (
                <FieldPhone
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldCalendar") {
              //   return (
              //     <FieldCalendar
              //       key={field.id}
              //       control={form.control}
              //       name={field.name}
              //       placeholder={field.placeholder}
              //       loading={false}
              //       label={field.label}
              //     />
              //   );
            } else if (field.itemType === "fieldTextArea") {
              return (
                <FieldTextArea
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  loading={false}
                  placeholder={field.placeholder || ""}
                  label={field.label}
                />
              );
            } else if (field.itemType === "fieldDropdown") {
              return (
                <FieldDropdown
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  label={field.label}
                  options={field.options}
                />
              );
            } else if (field.itemType === "fieldEmail") {
              return (
                <FieldText
                  key={field.id}
                  control={form.control}
                  name={field.name}
                  placeholder={field.placeholder || ""}
                  loading={false}
                  label={field.label}
                />
              );
            }
            return null;
          })}
          <div className="flex justify-center mt-10">
            <AppButton type="submit" label="Submit" className=" px-20" disabled={submitting} />
          </div>
        </form>
      </Form>

      <FormFooterRecaptchaPolicy />
    </div>
  );
};

export default ColumnForm;
