"use client";
import React from "react";
import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { SwiperSlide } from "swiper/react";
import PackageCard from "@/components/cards/PackageCard";
import Carousel from "@/components/Carousel";
// import { Play } from "@/assets/Icons";
import { Package } from "@/mocks/mocks";

interface PackagesProps {
  id?: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLink: string;
  ctaLabel: string;
  videoUrl: string;
  videoPoster: string;
  packages: Package[];
  paddingBottom?: string;
}

const Packages: React.FC<PackagesProps> = ({
  label,
  packages,
  ctaLabel,
  ctaLink,
  heading,
  videoUrl,
  videoPoster,
  subheading,
  id,
  paddingBottom,
}) => {
  // const [isPlaying, setIsPlaying] = useState(false);
  // const videoRef = useRef<HTMLVideoElement>(null);

  // async function onVideoClick() {
  //   if (videoRef.current?.paused) {
  //     videoRef.current?.play();
  //     setTimeout(() => {
  //       // Something really strange is happening in some browsers,
  //       // it seems that the video is not playing on the first click
  //       // but it plays on the second click, so we need to force it
  //       videoRef.current?.play();
  //     }, 300);
  //   } else {
  //     videoRef.current?.pause();
  //   }
  // }

  return (
    <ContentLayout bgColorClass={"bg-navy-blue-10"} id={id} paddingBottom={paddingBottom}>
      <div className="lg:grid-cols-12 xl:gap-10 lg:gap-6 grid grid-cols-4 gap-8">
        <div className="lg:col-span-5 xl:col-span-6 col-span-4">
          {label && (
            <AppText type={"LABEL_LARGE"} className={"mb-2 text-royal-blue"}>
              {label}
            </AppText>
          )}

          <AppText type={"HEADLINE_MEDIUM"}>{heading}</AppText>

          <AppText type={"SUB_HEADING_SMALL"} className={"mt-[16px]"}>
            {subheading}
          </AppText>

          <AppButton label={ctaLabel} link={ctaLink} intent={"secondary"} className={"mt-[32px]"} />
        </div>

        <div className="lg:col-span-7 xl:col-span-6 relative col-span-4">
          <video
            // ref={videoRef}
            // onClick={onVideoClick}
            // onPlaying={() => setIsPlaying(true)}
            // onPause={() => setIsPlaying(false)}
            className="min-h-[191px] w-full h-full  object-cover cursor-pointer"
            controls={true}
            preload="auto"
            poster={videoPoster}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>

          {/* {!isPlaying && (
            <div
              onClick={onVideoClick}
              className={
                "w-[56px] h-[56px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black-20 bg-opacity-80 rounded-2xl"
              }
            >
              <Play />
            </div>
          )} */}
        </div>
      </div>

      <Carousel
        wrapperClassName={"mt-[40px] flex flex-1"}
        className={"flex-1 flex items-stretch"}
        paginationType={"pageSelect"}
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 40,
          },
        }}
      >
        {packages.map(renderPackage)}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(Packages);

const renderPackage = ({ id, heading, subheading, ctaLink, ctaLabel, icon }: Package) => (
  <SwiperSlide key={id}>
    <PackageCard title={heading} subheading={subheading} buttonLink={ctaLink} iconUrl={icon} buttonLabel={ctaLabel} />
  </SwiperSlide>
);
