export const RECORD_MAPPING: { [key: string]: string } = {
  Service: "0128c000001zT4PAAU",
  Invoice: "0128c000001zT4PAAU",
  Relocation: "0128c000001zT4PAAU",
  "Lease Extension": "0128c000001zT4PAAU",
  Return: "0128c000001zT4PAAU",
  "AP Information": "0128c000001zT4PAAU",
};

export const ORIGINAL_SERVICE_NAME: { [key: string]: string } = {
  "Service a unit": "Service",
  "Invoice review": "Invoice",
  "Relocate a unit": "Relocation",
  "Lease extension": "Lease Extension",
  "Return a unit": "Return",
  "AP Information": "AP Information",
};
