import React, { useEffect } from "react";
import Image from "next/image";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import AppText from "@/components/AppText";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { AppButton } from "@/components/buttons/AppButton";
import { CustomFormMessage } from "@/components/CustomFormMessage";
import { useFormData } from "@/store/servicesFormsStore";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";

interface IOption {
  title: string;
  description: string;
  value: string;
  selected: boolean;
  onClick?: (value: string) => void;
  onToggle?: (value: string | undefined) => void;
  icon: string;
}

interface IFormData {
  groups?: Array<{
    heading?: string;
    cards?: Array<{ [key: string]: string }>;
    requiredError?: string;
  }>;
  ctaNext: string;
}

const selectServiceSchema = z.object({
  Request_Type__c: z.string().min(1, {
    message: "selectOneOfTheServices",
  }),
});

// export type SelectServiceSchema = z.infer<typeof selectServiceSchema>;

const SelectServiceOption: React.FC<IOption> = ({ title, description, value, selected, onClick, onToggle, icon }) => {
  return (
    <div
      className={cn("flex flex-col px-6 py-8 bg-navy-blue-10 gap-4 cursor-pointer", selected && "bg-navy-blue-20")}
      onClick={() => {
        onClick && onClick(value);
        onToggle && onToggle((!selected && value) || undefined);
      }}
    >
      <div className="flex flex-row justify-start gap-2">
        <div className="bg-royal-blue w-9 h-9 shrink-0 fill-white p-1 rounded-full">
          <Image src={icon} alt={title} width={36} height={36} className="invert" />
        </div>
        <AppText type="TITLE_MEDIUM">{title}</AppText>
      </div>
      <div>
        <AppText type="BODY_MEDIUM">{description}</AppText>
      </div>
    </div>
  );
};

const FormRequestSupportStep1 = ({ formData }: { formData: IFormData }) => {
  const setStepAndFormData = useFormData((state) => state.setStepAndFormData);
  const formDataStore = useFormData((state) => state.formData);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const form = useForm<z.infer<typeof selectServiceSchema>>({
    resolver: zodResolver(selectServiceSchema),
    // values: formData as SelectServiceSchema,
    defaultValues: {
      Request_Type__c: formDataStore.Request_Type__c || "",
    },
    mode: "onBlur",
  });

  const cards = formData.groups?.[0].cards || [];

  const onSubmit = (values: z.infer<typeof selectServiceSchema>) => {
    setStepAndFormData(2, values);
  };

  return (
    <>
      <Form {...form}>
        <form
          className="lg:col-span-10 lg:col-start-2 lg:px-10 col-span-4 px-0 bg-white"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
            <AppText type="TITLE_MEDIUM">{formData.groups?.[0].heading || "Select your service need"}</AppText>

            <FormField
              control={form.control}
              name="Request_Type__c"
              render={({ field, fieldState: { error } }) => (
                <FormItem className="sm:grid-cols-2 grid gap-6 space-y-0" ref={field.ref}>
                  <FormControl>
                    <>
                      {cards.map((option: { [key: string]: string }) => (
                        <SelectServiceOption
                          key={option.id}
                          title={option.heading}
                          description={option.subheading}
                          value={option.key}
                          selected={option.key === field.value}
                          onToggle={field.onChange}
                          icon={option.icon}
                        />
                      ))}
                    </>
                  </FormControl>
                  {error && (
                    <CustomFormMessage>
                      {formData.groups?.[0].requiredError || "Please select a service."}
                    </CustomFormMessage>
                  )}
                </FormItem>
              )}
            />
          </div>
          <div className="bg-white flex flex-row-reverse flex-wrap justify-between px-[40px]">
            <AppButton intent="primary" label={formData.ctaNext} className="px-10" type="submit" />
          </div>
        </form>
      </Form>
      <FormFooterRecaptchaPolicy />
    </>
  );
};

export default FormRequestSupportStep1;
