"use client";

import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Link from "next/link";

import ContentLayout from "./layouts/ContentLayout";
import { ExpandMore24 } from "@/assets/Icons";
import { AppButton } from "./buttons/AppButton";

interface IAnchor {
  label: string;
  id: string;
}

export interface IInPageAnchor {
  anchors: IAnchor[];
  paddingBottom?: string;
}

export interface IInPageAnchor {}

const InPageAnchor: React.FC<IInPageAnchor> = ({ anchors, paddingBottom }) => {
  const handleAnchorsClick = (id: string) => {
    if (id) {
      const target = document.getElementById(id);

      if (target) {
        const position = target.getBoundingClientRect();

        window.scrollTo(position.left, position.top + window.scrollY - 150);
      } else console.log("No Target with scroll ID");
    } else console.log("No scroll ID");
  };
  return (
    <ContentLayout bgColorClass="bg-black-5" innerClass="sm:py-2 py-2 px-0" paddingBottom={paddingBottom}>
      <div className="hidden lg:flex space-x-[60px] items-center justify-center w-full px-4 py-2">
        {anchors.map((anchor: IAnchor) => (
          <AppButton
            intent="ghost"
            label={anchor.label}
            key={anchor.id}
            onClick={() => handleAnchorsClick(anchor.id)}
            className="text-18"
          />
        ))}
      </div>
      <div className="lg:hidden flex">
        <MobileDropdown anchors={anchors} />
      </div>
    </ContentLayout>
  );
};

export default InPageAnchor;

const MobileDropdown: React.FC<IInPageAnchor> = ({ anchors }) => {
  return (
    <Listbox>
      <div className="relative flex-1 w-full">
        <Listbox.Button
          className={`focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 
          focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 
           relative w-full py-2 px-4 text-left   cursor-pointer `}
        >
          <span className="block truncate">{anchors[0].label}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ExpandMore24 />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options
            className={`max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm absolute w-full 
            overflow-auto text-base bg-white  shadow-lg text-black`}
          >
            {anchors.map((anchor, idx) => {
              if (idx > 0) {
                return (
                  <Link key={anchor.id} href={`#${anchor.id}`}>
                    <Listbox.Option
                      className={`relative cursor-pointer select-none py-2 px-4 text-black-80 hover:bg-navy-blue hover:text-white`}
                      value={anchor.label}
                    >
                      {anchor.label}
                    </Listbox.Option>
                  </Link>
                );
              }
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
