/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useParams, useRouter } from "next/navigation";
import { toast } from "sonner";

import FormGroupWrapper from "./FormGroupWrapper";
import LoadingDots from "@/components/LoadingDots";
import AppText from "@/components/AppText";
import { IDict } from "@/components/constants";
import { parseData_RAQBuy_unitData } from "./helpers";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import CustomFormLabel from "../CustomFormLabel";
import { Input } from "../ui/input";

const formatting_options = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
};

const formatPrice = (price: any) => {
  // const formattedPrice = new Intl.NumberFormat(price, formatting_options);

  // return formattedPrice;

  if (price?.toLocaleString("en-US", formatting_options)) {
    return price.toLocaleString("en-US", formatting_options);
  }

  return "$" + price;
};

const SalesProductInfo = ({
  dict,
  index,
  loading,
  setLoading,
  unit,
  setUnit,
  control,
  group,
}: {
  dict: IDict;
  index: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  unit: any;
  setUnit: (unit: any) => void;
  control: any;
  group: any;
}) => {
  const [unitNumber, setUnitNumber] = useState<string | null>(null);
  const inputField: any = group.rows?.[0]?.fields?.[0];

  const { slug } = useParams();
  const router = useRouter();

  useEffect(() => {
    if (slug && slug.length && slug.length === 1) {
      setUnitNumber(decodeURI(slug[0]));
    } else {
      router.push("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (unitNumber) {
      fetchUnit(unitNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitNumber]);

  const fetchUnit = async (unitNumber: string) => {
    setLoading(true);

    try {
      const data = await fetch("/api/contentful/sales-showroom/unit-number", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ unitNumber }),
      });

      const res = await data.json();

      if (res.error || !res.items?.[0]) {
        toast.error("Error fetching data from Contentful");
      } else {
        const _unit = parseData_RAQBuy_unitData(res.items[0], res.includes?.Entry || []);
        setUnit(_unit);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormGroupWrapper groupHeading={dict.productInformation} borderNumber={index}>
      {loading ? (
        <LoadingDots />
      ) : (
        <div className="flex flex-col mt-[26px] gap-[18px]">
          <AppText type="BODY_SMALL">
            {dict.unitNumber}: <span className="text-16 pl-1 font-semibold">{unit?.unitNumber}</span>
          </AppText>
          <AppText type="BODY_SMALL">
            {dict.productType}: <span className="text-16 pl-1 font-semibold">{unit?.productType}</span>
          </AppText>
          <AppText type="BODY_SMALL">
            {dict.unitLocation}: <span className="text-16 pl-1 font-semibold">{unit?.unitLocation}</span>
          </AppText>
          <AppText type="BODY_SMALL">
            {dict.salePrice}: <span className="text-16 pl-1 font-semibold">{formatPrice(unit?.price)}</span>
          </AppText>
        </div>
      )}

      {/**---------------------------------- Your Best Offer */}
      {unit?.obo && (
        <div className="lg:flex-row flex flex-col gap-6 mt-4 w-full lg:w-[calc(50%-12px)]">
          <FormField
            control={control}
            name="yourBestOffer"
            render={({ field }) => (
              <FormItem className="flex-1 space-y-0">
                <CustomFormLabel label={inputField.label} />
                <FormControl>
                  <Input
                    placeholder={inputField.placeholder}
                    {...field}
                    className="font-saira"
                    aria-describedby={undefined}
                  />
                </FormControl>

                <FormMessage className="font-saira text-14 font-[400]" />
              </FormItem>
            )}
          />
        </div>
      )}
    </FormGroupWrapper>
  );
};

export default SalesProductInfo;
