/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Form } from "@/components/ui/form";
import { IDict } from "@/components/constants";
import FormGroupWrapper from "./FormGroupWrapper";
import { cn } from "@/lib/utils";
import AppText from "@/components/AppText";
import AppButton from "@/components/buttons/AppButton";
import { getGeocodingAddress, preparePayloadRAQ } from "@/components/form/helpers";
import {
  FieldCalendar,
  FieldDropdown,
  FieldDropdownIndustries,
  FieldFallbackIndustries,
  FieldGoogleAddress,
  FieldOptionsCards,
  FieldPhone,
  FieldText,
  FieldTextArea,
} from "./FormElements";
import { createDynamicSchemaSupportThirdTab } from "./schemaHelper";
import { FormFooterRecaptchaPolicy } from "../recaptcha/FormFooterRecaptchaPolicy";

interface ICheckBox {
  [key: string]: boolean;
}

const FormTabRequestQuote = ({ tab, dict }: { tab: any; dict: IDict }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formSchema = z.object({});

  const [selectedPlace, setSelectedPlaceBuy] = useState<google.maps.places.PlaceResult | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [dynamicFormSchema, setDynamicFormSchema] = useState<z.ZodSchema>(formSchema);
  const [radioCards, setRadioCards] = useState<{ [key: string]: string | undefined } | Record<string, never>>(
    tab.radioButtonsDefaultValues || {}
  );
  const [checkBoxCard, setCheckBoxCard] = useState<ICheckBox>(tab.checkboxesDefaultValues || {});

  const siteAddress = useRef("");
  const googlePlacesTouched = useRef(false);

  const router = useRouter();

  const formDefaultValues = useMemo(() => {
    const res: any = { cards: "", [tab?.industryFallback?.name || "Other Industry"]: "" };

    (tab.groups || []).forEach((group: any) => {
      (group.rows || []).forEach((row: any) => {
        (row.fields || []).forEach((field: any) => {
          res[field.name] = "";
        });
      });
    });

    return res;
  }, [tab]);

  const form = useForm<z.infer<typeof dynamicFormSchema>>({
    resolver: zodResolver(dynamicFormSchema),
    defaultValues: formDefaultValues,
    mode: "onBlur",
  });

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const geoRes = await getGeocodingAddress(selectedPlace?.formatted_address);

      form.setValue("site_address_country", geoRes.country);
      form.setValue("site_address_state", geoRes.state);
      form.setValue("site_address_zip", geoRes.zipCode);
      form.setValue("site_address_city", geoRes.city);

      if (geoRes.address) {
        if (geoRes.streetNumber) {
          siteAddress.current = `${geoRes.streetNumber} ${geoRes.address}`;
        } else {
          siteAddress.current = `${geoRes.address}`;
        }
      } else if (geoRes.formatted_address) {
        siteAddress.current = geoRes.formatted_address;
      } else {
        siteAddress.current = geoRes.neighborhood;
      }

      setTimeout(() => {
        form.trigger("site_address_country");
        form.trigger("site_address_state");
        form.trigger("site_address_zip");
        form.trigger("site_address_city");
      }, 500);
    }
  };

  useEffect(() => {
    if (selectedPlace && selectedPlace?.formatted_address) {
      getSiteData();
      form.setValue("Address", "valid", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    } else {
      if (googlePlacesTouched.current) {
        form.setValue("Address", "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const watchIndustry = form.watch("Industries");

  useEffect(() => {
    const updateSchema = () => {
      const baseSchema = createDynamicSchemaSupportThirdTab(tab.groups);

      const updatedSchema = z.object({
        ...baseSchema,
        [tab?.industryFallback?.name || "Other Industry"]:
          watchIndustry === "Other"
            ? z
                .string()
                .min(tab?.industryFallback?.min || 2, tab?.industryFallback?.minError || "Minimum 2 characters")
                .max(tab?.industryFallback?.max || 50, tab?.industryFallback?.maxError || "Maximum 50 characters")
            : z.string().optional(),
      });

      setDynamicFormSchema(updatedSchema);
    };

    watchIndustry !== "" && updateSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIndustry]);

  useEffect(() => {
    const createDynamicSchema = () => {
      const zObj = createDynamicSchemaSupportThirdTab(tab.groups);

      const formSchema = z
        .object({
          ...zObj,
        })
        .refine(
          (data) => {
            if (data.Industries.toLowerCase() === "other") {
              return (
                !!data[tab?.industryFallback?.name || "Other Industry"] &&
                data[tab?.industryFallback?.name || "Other Industry"].trim() !== ""
              );
            }
            return true;
          },
          {
            message: "Other Industry is required when 'Other' is selected",
            path: [tab?.industryFallback?.name || "Other Industry"],
          }
        );

      setDynamicFormSchema(formSchema);
    };

    createDynamicSchema();
  }, [tab]);

  const deliveryAddressBlur = () => {
    googlePlacesTouched.current = true;
  };

  const handleDeliveryAddressOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setSelectedPlaceBuy(null);
    }
  };

  const handleCheckboxes = (item: string, checked: boolean) => {
    const tempCheckBoxes: any = { ...checkBoxCard, [item]: checked };
    const tempRadioButtons = { ...radioCards };

    setCheckBoxCard(tempCheckBoxes);

    const isRadioChecked = Object.keys(tempRadioButtons).filter((item: string) => !!tempRadioButtons[item]);
    const isCheckboxChecked = Object.keys(tempCheckBoxes).filter((item: string) => !!tempCheckBoxes[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
    form.trigger("cards");
  };

  const updateRadioButtons = (group: string, value: string, del: boolean) => {
    const temp = { ...radioCards };
    if (del) {
      temp[group] = "";

      setRadioCards(temp);
    } else {
      temp[group] = value;
      setRadioCards(temp);
    }

    const isRadioChecked = Object.keys(temp).filter((item: string) => !!temp[item]);

    const isCheckboxChecked: any = Object.keys(checkBoxCard).filter((item: string) => !!checkBoxCard[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
    form.trigger("cards");
  };

  async function onSubmit() {
    if (!executeRecaptcha) {
      toast.error("Error submitting request - reCAPTCHA not loaded");
      return;
    }

    setSubmitting(true);

    const token = await executeRecaptcha("submit_request_quote");

    const formValues = form.getValues();

    const recaptchaResponse = await fetch("/api/recaptcha", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    });

    const recaptchaData = await recaptchaResponse.json();

    if (!recaptchaData.success) {
      console.log(recaptchaData);
      toast.error("reCAPTCHA verification failed");
      setSubmitting(false);
      return;
    }

    console.log("reCAPTCHA verification successful", recaptchaData);

    const payload = await preparePayloadRAQ(
      formValues,
      radioCards,
      checkBoxCard,
      siteAddress?.current,
      tab?.industryFallback?.name || "Other Industry"
    );

    try {
      const response = await fetch("/api/request-quote", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      // request is valid if data is empty string
      if (data.error || data.message) {
        console.log(data);
        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push("/request-quote/thank-you");
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }

  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  return (
    <>
      <div className="lg:col-span-10 lg:col-start-2 lg:px-10 pt-[50px] col-span-4 px-6 bg-white">
        <Form {...form}>
          <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
            {(tab.groups || []).map((group: any, index: number) => (
              <FormGroupWrapper
                key={group.id}
                groupHeading={group.heading}
                borderNumber={index + 1}
                groupDescription={group.description}
                noBorder={index === tab.groups.length - 1}
                noBottomPadding={group.groupType === "fieldGroupOptionCards"}
              >
                {group.groupType === "fieldGroupOptionCards" ? (
                  <FieldOptionsCards
                    group={group}
                    key={group.id}
                    control={form.control}
                    name="cards"
                    handleCheckboxes={handleCheckboxes}
                    checkBoxCard={checkBoxCard}
                    updateRadioButtons={updateRadioButtons}
                    radioCardsState={radioCards}
                  />
                ) : (
                  (group.rows || []).map((row: any) => (
                    <div key={row.id} className="lg:flex-row flex flex-col gap-6">
                      {row.fields?.length &&
                        row.fields.map((field: any) => {
                          if (field.fieldType === "fieldText") {
                            return (
                              <FieldText
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldPhone") {
                            return (
                              <FieldPhone
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder || "Enter your phone number..."}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldCalendar") {
                            return (
                              <FieldCalendar
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          } else if (field.fieldType === "fieldGoogleAddress") {
                            return (
                              <FieldGoogleAddress
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                                deliveryAddressBlur={deliveryAddressBlur}
                                setSelectedPlaceBuy={setSelectedPlaceBuy}
                                handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                              />
                            );
                          } else if (field.fieldType === "fieldTextArea") {
                            return (
                              <FieldTextArea
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                loading={false}
                                placeholder={field.placeholder}
                              />
                            );
                          } else if (field.fieldType === "fieldDropdown") {
                            if (field.name === "Industries") {
                              // special case of fieldDropdown
                              return (
                                <div key={field.id} className="flex flex-col flex-1 gap-6">
                                  <FieldDropdownIndustries
                                    control={form.control}
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    label={field.label}
                                    options={field.options}
                                  />
                                  <FieldFallbackIndustries
                                    watchIndustry={watchIndustry}
                                    control={form.control}
                                    name={tab?.industryFallback?.name || "Other Industry"}
                                    placeholder={field.placeholder}
                                    label={field.label}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <FieldDropdown
                                  key={field.id}
                                  control={form.control}
                                  name={field.name}
                                  placeholder={field.placeholder}
                                  label={field.label}
                                  options={field.options}
                                />
                              );
                            }
                          } else if (field.fieldType === "fieldEmail") {
                            return (
                              <FieldText
                                key={field.id}
                                control={form.control}
                                name={field.name}
                                placeholder={field.placeholder}
                                loading={false}
                                label={field.label}
                              />
                            );
                          }
                          return null;
                        })}
                    </div>
                  ))
                )}
              </FormGroupWrapper>
            ))}

            {/******************* SUBMIT BUTTON ************************ */}
            <div className="bg-white flex flex-row-reverse flex-wrap justify-start px-[10] mt-10">
              {submitting ? (
                <button
                  id="button-request-quote-buy"
                  className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-3
               transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
                  disabled
                >
                  <div
                    className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                  ></div>
                  <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                    {dict.submitting}
                  </AppText>
                </button>
              ) : (
                <>
                  <button id="targetSubmit" className="hidden" aria-label="submit target" type="submit"></button>

                  <AppButton
                    intent="primary"
                    label={dict.submit}
                    type="submit"
                    className="px-10"
                    onMouseDown={handleFormSubmit}
                  />
                </>
              )}
            </div>
          </form>
        </Form>
        <FormFooterRecaptchaPolicy />
      </div>
    </>
  );
};

export default FormTabRequestQuote;
