"use client";

import React, { useState } from "react";
import Image from "next/image";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";

import AppText from "@/components/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { cn } from "@/lib/utils";

interface HeaderVideoProps {
  id: string;
  subheading: string;
  ctaLabel: string;
  video?: string;
  lightboxVideo?: string;
  headlineAlt: string;
  taglineImage: string;
  placeholder: string;
  paddingBottom?: string;
}

const HeaderVideo: React.FC<HeaderVideoProps> = ({
  id,
  subheading,
  ctaLabel,
  video,
  headlineAlt,
  lightboxVideo,
  taglineImage,
  // placeholder,
  paddingBottom,
}) => {
  const [lightbox, setLightbox] = useState(false);

  return (
    <>
      <div
        className={cn(
          "flex relative flex-col w-full items-center justify-center bg-slate videoHeaderHeight bg-navy-blue",
          paddingBottom
        )}
        id={id}
      >
        {video && (
          <video
            className={"w-full h-full object-cover absolute"}
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
          >
            <source src={video} type="video/mp4" />
          </video>
        )}

        <div className={"max-w-[540px] flex flex-col items-center z-10"}>
          {/* <AppText type={"HEADLINE_LARGE"} className={"text-white text-center uppercase px-4"}>
            {formatHeading(headlineAlt, taglineImage)}
          </AppText> */}

          <div className="relative">
            <Image
              priority
              unoptimized
              src={taglineImage}
              alt={headlineAlt}
              sizes="100vw"
              width={0}
              height={0}
              // fill
              // placeholder="blur"
              // blurDataURL={placeholder}
              className="object-cover w-full px-4"
            />
          </div>

          <AppText type={"SUB_HEADING_SMALL"} as="h1" className={"text-white text-center font-sairaCondensed px-4"}>
            {subheading}
          </AppText>

          {lightboxVideo && (
            <div className={"flex mt-8 flex-col sm:flex-row items-center"}>
              <AppButton label={ctaLabel} bg={"dark"} onClick={() => setLightbox(true)} />
            </div>
          )}
          <div className={"bg-mellow w-[1px] h-[100px] mt-[56px]"} />
        </div>
      </div>

      {lightboxVideo && (
        <Lightbox
          open={lightbox}
          close={() => setLightbox(false)}
          plugins={[Video]}
          slides={[
            {
              type: "video",
              width: 1280,
              height: 720,
              autoPlay: true,
              sources: [
                {
                  src: lightboxVideo,
                  type: "video/mp4",
                },
              ],
            },
          ]}
          carousel={{ finite: true }}
          noScroll={{ disabled: true }}
          controller={{ closeOnBackdropClick: true }}
        />
      )}
    </>
  );
};

export default React.memo(HeaderVideo);
