/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useMemo } from "react";
import ContentLayout from "./layouts/ContentLayout";
import AppText from "./AppText";
import RichTextRenderer from "./RichTextRenderer";
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

interface ITableCompareFeatures {
  id: string;
  label: string;
  content: any;
  table: any;
  heading: string;
  tableStyle?: string;
  mostPopularColumn?: number;
  mostPopularString: string;
  paddingBottom?: string;
}

const TableCompareFeatures = ({
  id,
  content,
  label,
  heading,
  table,
  tableStyle,
  mostPopularColumn,
  mostPopularString,
  paddingBottom,
}: ITableCompareFeatures) => {
  const { _table, paragraphs } = useMemo(() => {
    const filteredTable = (table?.content || []).filter((node: any) => node.nodeType === "table");
    const paragraphs = (table?.content || []).filter((node: any) => node.nodeType === "paragraph");

    return {
      _table: filteredTable?.[0] || [],
      paragraphs: (paragraphs || []).map((node: any) => node?.content?.[0]?.value || ""),
    };
  }, [table]);

  return (
    <ContentLayout id={id} paddingBottom={paddingBottom}>
      <div className="flex flex-col items-center w-full">
        {label && (
          <AppText type="LABEL_LARGE" className="text-center max-w-[736px] text-royal-blue mb-2">
            {label}
          </AppText>
        )}
        {heading && (
          <AppText type="HEADLINE_MEDIUM" className="text-center max-w-[736px] mb-4">
            {heading}
          </AppText>
        )}

        {content && (
          <RichTextRenderer
            richTextDocument={content}
            id={`${id}-content`}
            locations
            className="text-sm  max-w-[736px] text-center"
          />
        )}
      </div>
      {tableStyle === "Checkmarks" && <FeaturesTable data={_table} />}
      {tableStyle === "Numbers" && (
        <NumbersTable data={_table} mostPopularColumn={mostPopularColumn} mostPopularString={mostPopularString} />
      )}
      {paragraphs && paragraphs.length && (
        <div className="flex flex-col gap-1 mt-4">
          {paragraphs.map((p: string, i: number) => (
            <AppText type="BODY_SMALL" key={i} className="max-w-[736px]">
              {p}
            </AppText>
          ))}
        </div>
      )}
    </ContentLayout>
  );
};

export default TableCompareFeatures;

const FeaturesTable = ({ data }: { data: any }) => {
  const rows = useMemo(() => {
    const allRows = data?.content || [];

    return allRows.map((row: any) => {
      const subRow = row.content || [];

      return subRow.map((sr: any) => sr.content?.[0]?.content?.[0]?.value);
    });
  }, [data]);
  const rowBlocksWidth = `${100 / (rows?.[0]?.length - 1)}%`;

  return (
    <div className="overflow-x-auto">
      <div className="lg:block hidden">
        {rows.map((row: string[], i: number) => (
          <div
            key={i}
            className={cn(
              "flex",
              i % 2 ? "bg-white" : "bg-black-5",
              i === 0
                ? "font-sairaSemiCondensed text-16 font-[600] tracking-[0.5px] leading-[1.5] text-center"
                : "font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] text-center"
            )}
          >
            {row.map((cell: string, n: number) => (
              <div
                className={cn(
                  i === 0
                    ? n === 0
                      ? "bg-black-5 p-4 flex-1 items-center flex uppercase "
                      : "bg-royal-blue text-white p-4 flex-1 uppercase"
                    : "featuresRowTable",
                  n === 0
                    ? "min-w-[249px] flex-1 p-[14px] border-b-[1px] border-l-[1px] border-black-10 text-left"
                    : "flex-1 p-[14px] border-b-[1px] border-l-[1px] border-black-10 last-of-type:border-r-[1px] flex items-center justify-center"
                )}
                key={n}
              >
                {cell === "X" ? <Check size={20} aria-label="check icon" /> : cell}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="lg:hidden w-fit min-w-[900px] flex flex-col">
        {rows.map((row: string[], i: number) => {
          if (i === 0) {
            return (
              <div
                key={i}
                className={cn(
                  "flex font-sairaSemiCondensed w-full uppercase text-16 font-[600] tracking-[0.5px] leading-[1.5] text-center"
                )}
              >
                {row.map((cell: string, n: number) => {
                  if (n > 0) {
                    return (
                      <div
                        className={cn(
                          "bg-royal-blue last-of-type:border-r-[1px] border-l-[1px] border-b-[1px] border-black-10 p-4 flex-1 text-white flex items-center justify-center"
                        )}
                        key={n}
                      >
                        {cell}
                      </div>
                    );
                  }
                })}
              </div>
            );
          }

          return (
            <React.Fragment key={i + "featureName"}>
              <div
                className={cn(
                  "bg-black-5 px-4 w-full py-2 font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] flex text-left"
                )}
              >
                {row.map((cell: string, n: number) => (
                  <div className={`max-w-[${rowBlocksWidth}]`} key={n}>
                    {n === 0 ? cell : " "}
                  </div>
                ))}
              </div>
              <div className="flex bg-white font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] text-center">
                {row.map((cell: string, n: number) => {
                  if (n > 0) {
                    return (
                      <div
                        className={cn(
                          "featuresRowTable flex-1 px-4 py-2 last-of-type:border-r-[1px] border-l-[1px] border-b-[1px] border-black-10 flex items-center justify-center"
                        )}
                        key={n}
                      >
                        {cell === "X" ? <Check size={20} aria-label="check icon" /> : cell}
                      </div>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const NumbersTable = ({
  data,
  mostPopularColumn,
  mostPopularString,
}: {
  data: any;
  mostPopularColumn: number | undefined;
  mostPopularString: string;
}) => {
  const rows = useMemo(() => {
    const allRows = data?.content || [];

    return allRows.map((row: any) => {
      const subRow = row.content || [];

      return subRow.map((sr: any) => sr.content?.[0]?.content?.[0]?.value);
    });
  }, [data]);
  const rowBlocksWidth = `${100 / (rows?.[0]?.length - 1)}%`;

  return (
    <div className="pt-14 overflow-x-auto">
      <div className="lg:block hidden">
        {rows.map((row: string[], i: number) => (
          <div
            key={i}
            className={cn(
              "flex gap-2",
              i === 0
                ? "font-sairaSemiCondensed text-16 font-[600] tracking-[0.5px] leading-[1.5] text-center"
                : "font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] text-center"
            )}
          >
            {row.map((cell: string, n: number) => (
              <div
                className={cn(
                  i % 2 ? "bg-white" : "bg-black-5",
                  i === 0
                    ? n === 0
                      ? "bg-black-5 p-4 flex-1 items-center flex uppercase "
                      : mostPopularColumn && mostPopularColumn === n + 1
                      ? `bg-royal-blue text-white p-4 flex-1 uppercase relative overflow-visible
                      before:absolute before:bg-mellow-80 before:w-full before:h-9 before:left-0 before:right-0 before:top-[-33px]
                      before:content-center before:content-['most_popular'] before:text-12 before:block before:text-black before:rounded-t-[7px]`
                      : "bg-navy-blue-80 text-white p-4 flex-1 uppercase"
                    : "featuresRowTable",
                  n === 0
                    ? "min-w-[249px] flex-1 p-[14px] border-b-[1px] border-[1px] border-black-10 text-left"
                    : "flex-1 p-[14px] border-b-[1px] border-[1px] border-black-10 last-of-type:border-r-[1px] flex items-center justify-center"
                )}
                key={n}
              >
                {n === 0 ? (
                  cell
                ) : cell === "" || i === 0 ? (
                  cell
                ) : (
                  <div className="w-7 h-7 bg-royal-blue flex items-center justify-center text-white rounded-full">
                    {cell}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="lg:hidden w-fit min-w-[900px] flex flex-col">
        {rows.map((row: string[], i: number) => {
          if (i === 0) {
            return (
              <div
                key={i}
                className={cn(
                  "flex font-sairaSemiCondensed w-full uppercase text-16 font-[600] tracking-[0.5px] leading-[1.5] text-center"
                )}
              >
                {row.map((cell: string, n: number) => {
                  if (n > 0) {
                    return (
                      <div
                        className={cn(
                          "bg-royal-blue last-of-type:border-r-[1px] border-l-[1px] border-b-[1px] border-black-10 p-4 flex-1 text-white flex items-center justify-center"
                        )}
                        key={n}
                      >
                        {cell}
                      </div>
                    );
                  }
                })}
              </div>
            );
          }

          return (
            <React.Fragment key={i + "featureName"}>
              <div
                className={cn(
                  "bg-black-5 px-4 w-full py-2 font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] flex text-left"
                )}
              >
                {row.map((cell: string, n: number) => (
                  <div className={`max-w-[${rowBlocksWidth}] last-of-type:border-r-[1px] border-black-10`} key={n}>
                    {n === 0 ? cell : " "}
                  </div>
                ))}
              </div>
              <div className="flex bg-white font-saira text-14 font-[500] tracking-[-0.014px] leading-[1.7] text-center">
                {row.map((cell: string, n: number) => {
                  if (n > 0) {
                    return (
                      <div
                        className={cn(
                          "featuresRowTable flex-1 px-4 py-2 last-of-type:border-r-[1px] border-l-[1px] border-b-[1px] border-black-10 flex items-center justify-center"
                        )}
                        key={n}
                      >
                        {cell === "X" ? <Check size={20} aria-label="check icon" /> : cell}
                      </div>
                    );
                  }
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
